<main class="min-h-screen flex flex-col">
  <!-- heading -->
  <section>
    <div
      class="container mx-auto flex items-center justify-center flex-col"
    >
      <div class="text-center lg:w-2/3 w-full relative mt-16 md:mt-32">
        <div
          class="absolute left-1/2 transform top-10 -translate-x-1/2 h-72 lg:h-96 w-1 bg-[#d5b634] -z-10"
        ></div>
        <h1
          class="title-font sm:text-9xl text-6xl mb-4 font-medium lora uppercase"
        >
          Contact
        </h1>
        <!-- details -->
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="grid grid-cols-2 gap-8 lg:gap-16">
            <div class="lg:py-24">
              <div
                class="text-xs sm:text-sm lg:text-lg -mt-10 lg:-mt-20 text-right"
              >
                <p>LuxuryStar Travels Team</p>
                <span class="font-semibold"> Email Address</span>

                <br />
                • info@luxurystarstravels.com <br />
                <span class="font-semibold"> Contact Number </span>
                <br />
                • 800-352-9736
              </div>
            </div>
            <div class="lg:py-24">
              <p
                class="nunito-sans text-xs sm:text:sm lg:text-lg text-left"
              >
                Get in touch with the LuxuryStar Travels Team We are here to
                answer any questions, concerns, queries or suggestions you
                may have about services. Reach out to us and we'll respond
                as soon as we can. Please provide a valid email address and
                your name, in order for us to quickly reply to you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- form -->
  <section class="bg-[#ececec] body-font relative h-screen">
    <div class="container px-5 py-24 mx-auto">
      <div class="lg:w-1/2 md:w-2/3 mx-auto">
        <div class="mx-auto w-full px-4 pb-24 sm:px-6 lg:px-8">
          <form action="" class="mx-auto mt-8 mb-0 max-w-2xl space-y-4">
            <div>
              <label
                for="UserEmail"
                class="block font-medium text-[#171717] nunito-sans"
              >
                Email
              </label>

              <input
                type="email"
                id="UserEmail"
                placeholder="Email Address"
                class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
              />
            </div>
            <div>
              <label
                for="name"
                class="block font-medium text-[#171717] nunito-sans"
              >
                Name
              </label>

              <input
                type="text"
                id="name"
                placeholder="Your name"
                class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
              />
            </div>
            <div class="relative">
              <label
                for="password"
                class="block font-medium text-[#171717] nunito-sans"
              >
                Message
              </label>

              <textarea
                id="message"
                name="message"
                placeholder="Your message here..."
                class="mt-1 bg-[#D9D9D9] border-none h-32 text-base outline-none text-[#171717] p-4 resize-none leading-6 w-full shadow-sm"
              ></textarea>
            </div>
            <br />

            <button
              type="submit"
              class="inline-block bg-[#171717] py-4 font-medium text-white w-full hover:bg-transparent border hover:text-[#171717] transition-all ease-linear border-[#171717]"
            >
              Contact Us
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
