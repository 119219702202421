export class APIResponse <T = any>{
  constructor(
    public status: 'success' | 'fail' | 'error',
    public message?: string,
    public data?: { [index: string]: T },
    public results?: number,
    public numOfPages?: number,
) {
};

  static Error(status: 'error' | 'fail', message: string): APIResponse {
      return new APIResponse(status, message);
  }
}
