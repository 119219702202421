import { Component, OnInit,ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { NavigationStart, Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  isLoggedIn: boolean = false;

  showHeader: boolean = true;
  @ViewChild('header') headerRef?: ElementRef<HTMLHeadingElement>;

  showMenu: boolean = false;

  toggleMenu() {
      this.showMenu = !this.showMenu;
  }

  //Constructor & Initialize Logged In
  constructor(private authService: AuthService, private uiService: UiService,
              private router: Router) {
                this.authService.isLoggedIn$.subscribe(val =>{
                  this.isLoggedIn = val;
                });
                this.uiService.showHeader$.subscribe(val => this.showHeader = val);
  }

  ngOnInit(): void {
    // this.showMenu = true;
     //Original Code below
     this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
          this.showMenu = false;
      }
  });
  }

  ngAfterViewInit(): void {
    // Header scroll animation
    if (this.headerRef?.nativeElement) {
      //window.addEventListener('scroll', this.toggleHeader.bind(this), { passive: true });
    }
  }
//   private toggleMenuButtons(): void {
//     this.deviceInfo = this.deviceService.getDeviceInfo();
//     const isMobile = this.deviceService.isMobile();
//     // console.log(`Device Info: ${this.deviceInfo[0]}`);
//     if(isMobile){
//         this.showHeaderButtons = false;
//         this.showMenu = true;
//         this.showMenu2 = true;
//     }
// }

  private toggleHeader() {
      const header = this.headerRef?.nativeElement;
      if (!header) return;

      const atThreshold = window.scrollY > 32;

      header.classList.toggle('bg-white', atThreshold);
  }

  logout(): void {
      this.authService.logout();
  }

}
