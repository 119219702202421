<!-- <br/> -->
<main class="min-h-screen flex flex-col bg-[#ececec]">
  <section>
    <div class="px-4 mx-auto mt-20 max-w-screen-2xl mb-14 sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-x-16">
        <div class="grid grid-cols-3 gap-4 sm:grid-cols-4">
          <div
            class="col-span-3 sm:col-span-4 lg:col-span-4 max-h-[570px] overflow-hidden"
          >
          <!-- Tours Video -->
          <!-- <div class="overflow-hidden lg:order-last lg:h-full">
            <video class="object-cover w-full h-full" controls>
              <source src="/assets/videos/homepage.mov" type="video/mp4" />
            </video>
          </div> -->
          <!--  End Tours Video -->
          <div class="mx-auto lg:mx-0 text-left text-[#171717]">
            <h2 class="text-4xl sm:text-6xl lora">Discover Jamaica</h2>

            <p class="mt-6 text-lg font-light nunito-sans lg:w-3/4">
              Here at Luxury Star Travels we are Jamaica's premier black car service,
              with the largest fleet of Luxury Sedans, SUV's Sprinters & buses.
              Our drivers have chauffeured some of Jamaica's former prime ministers
              alongside international icons and celebrities that have visited the island.
              We extend the same treatment to all our clients, celebrities or not.
              We specialize in catering to our client's needs, that's why a private concierge
              adheres to our clients needs prior and while on the island.
              Sit back and relax, we have you from here. Welcome to Jamaica!          </p>
            <div class="grid grid-cols-2 gap-4 mt-10 text-lg sm:grid-cols-3 nunito-sans">
              <div>
                <h2>Cost (per person)</h2>

                <p class="mt-1 font-light sm:block">Pricing: Depends on Location</p>
              </div>
              <div>
                <h2>Ages</h2>

                <p class="mt-1 font-light sm:block">
                  0 - 120
                </p>
              </div>
              <div>
                <h2 class="text-bold">Duration</h2>
                <p class="mt-1 font-light sm:block">We go the Extra Mile</p>
              </div>
              <div>
                <h2>Start Time</h2>
                <p class="mt-1 font-light sm:block">Check Availability</p>
              </div>
              <div>
                <h2>Ticket type</h2>
                <p class="mt-1 font-light sm:block">Mobile</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <!-- Insert Form -->
        <section class="lg:-mt-24">
          <div class="mx-auto">
            <div
              class="flex flex-col items-center w-full my-12 text-center border border-black"
            >
              <h1
                class="sm:text-3xl text-2xl title-font lora lg:text-left text-[#171717] py-5"
              >
                Book Your Transportation Now!
              </h1>
            </div>
            <div class="w-full pb-16">

              <form (ngSubmit)="addBooking(transportResForm)" class="mt-8 mb-0 space-y-4" #transportResForm = "ngForm">
                <div class="flex flex-wrap gap-y-5">
                  <!-- Guest Name -->
                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      for="guest_name"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Guest Name
                    </label>
                    <input type="text" id="guest_name" name="guest_name"
                           [(ngModel)]="data.guest_name"
                           pattern="[A-Za-z\-\'\s]+" required
                           class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"/>
                  </div>
                  <!-- Guest Name Error -->
                  <div class="relative h-6">
                    <error-display class="absolute"
                        *ngIf="((transportResForm.submitted || guest_name?.touched) && guest_name?.errors?.['required'])">
                        Please enter your name
                    </error-display>
                    <error-display class="absolute"
                        *ngIf="((transportResForm.submitted || guest_name?.touched) && guest_name?.errors?.['pattern'] && !guest_name?.errors?.['required'])">
                        Name can only contain letters, single spaces, hyphens, and apostrophises
                    </error-display>
                  </div>
                  <!-- End Guest Name Error -->
                  <!-- Email -->
                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      for="email"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Email Address
                    </label>
                    <input type="email"
                            [(ngModel)]="data.email"
                            id="email" name="email"
                            class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"/>
                   </div>

                  <!-- Email Error -->
                  <div class="relative h-6">
                    <error-display class="absolute"
                        *ngIf="((transportResForm.submitted || guest_name?.touched) && guest_name?.errors?.['required'])">
                        Please enter your email address
                    </error-display>
                    <error-display class="absolute"
                        *ngIf="((transportResForm.submitted || guest_name?.touched) && guest_name?.errors?.['pattern'] && !guest_name?.errors?.['required'])">
                        Please enter a valid email address
                    </error-display>
                  </div>
                  <!-- End Email Error -->

                  <!-- Phone Number -->
                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      for="phone"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Phone Number
                    </label>
                    <input type="text"
                            [(ngModel)]="data.phone"
                            id="phone" name="phone"
                            class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"/>
                  </div>

                  <!-- no. of persons -->

                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      No. of Persons
                      <span class="text-xs lg:text-sm"
                        >(including children)</span
                      >
                    </label>

                    <input
                      [(ngModel)]="data.pax"
                      id="pax" name="pax"
                      type="number"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>

                  <!-- location -->
                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      for="UserEmail"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Pick-up Location
                    </label>

                    <select
                      [(ngModel)]="data.pickup_loc"
                      name="pickup_loc"
                      id="pickup_loc"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    >
                      <option value="" disabled selected>
                        Select a pick-up location
                      </option>
                      <option value="MBJ Airport">MBJ Airport</option>
                      <option value="KIN Airport">Norman Manley Airport</option>
                      <option value="Other Airport">Other Airport</option>
                      <option value="Hotel or Villa Montego Bay">Hotel/Villa In Montego Bay</option>
                      <option value="Hotel or Villa Negril">Hotel/Villa In Negril</option>
                      <option value="Hotel or Villa Ocho Rios">Hotel/Villa In Ocho Rios</option>
                      <option value="Hotel or Villa Portland">Hotel/Villa In Portland</option>
                      <option value="Hotel or Villa">Other Hotel/Villa</option>

                    </select>
                  </div>


                  <!-- Pickup Details-->

                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Pickup Details
                    </label>

                    <input
                      [(ngModel)]="data.pickup_loc_det"
                      id="pickup_loc_det" name="pickup_loc_det"
                      type="text"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>
                  <!-- Vehicle Type-->

                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Choose Your Transportation Vehicle
                    </label>

                    <select
                        [(ngModel)]="data.vehicle_type"
                        (change)='onSelect(val.value)' #val
                        id="vehicle_type" name="vehicle_type"
                        class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]">
                        <option selected disabled value="null">-- Select Equipment --</option>
                        <option *ngFor="let item of vehicles" [value]="item.name">
                           {{ item.name }}
                        </option>
                    </select>
                  </div>
                  <div class="w-full p-2 lg:w-1/2">
                    <img src="{{selectedImage}}" class="w-40"/>
                  </div>

                  <!-- travel date -->
                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Travel Date
                    </label>

                    <input
                      type="datetime-local"
                      [(ngModel)]="data.travel_dt"
                      id="travel_dt"
                      name="travel_dt"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>

                  <!-- Destination -->
                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Destination
                    </label>

                    <input
                      [(ngModel)]="data.destination_loc_det"
                      type="text"
                      id="destination_loc_det"
                      name="destination_loc_det"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>

                  <!-- Round Trip -->
                  <div class="w-full p-2 lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Will you require Return?
                    </label>
                    <select
                      (change)='onSetBookingFreq()'
                      [(ngModel)] = "retStatus"

                      id="booking_freq"
                      name="booking_freq"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    >
                        <option
                          *ngFor="let retStatus_item of retStatus_values"
                          [selected] = "retStatus_item === retStatus"
                          value={{retStatus_item}}>
                          {{retStatus_item}}
                        </option>
                    </select>
                  </div>
                  <br/>
                  <!-- return travel date -->
                  <div class="w-full p-2 lg:w-1/2"
                  *ngIf="retStatus === 'Yes'">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Return Date
                    </label>
                  <input

                    type="datetime-local"
                    [(ngModel)]="data.ret_travel_dt"
                    name="ret_travel_dt"
                    id="ret_travel_dt"
                    class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                  />
                  </div>
                  <br>
                  <!-- Return Pickup Details-->
                  <div
                      *ngIf="retStatus === 'Yes'"
                      class="w-full p-2 lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Return Pickup Location
                    </label>

                    <input
                      [(ngModel)]="data.ret_pickup_loc_det"
                      id="ret_pickup_loc_det" name="ret_pickup_loc_det"
                      type="text"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>
                  <!-- Return Destination Details-->
                  <div
                      *ngIf="retStatus === 'Yes'"
                      class="w-full p-2 lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Return Dropoff Location
                    </label>

                    <input
                      [(ngModel)]="data.ret_destination_loc_det"
                      id="ret_destination_loc_det" name="ret_destination_loc_det"
                      type="text"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>
                  <br/>
                  <!-- Other Service -->
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Other Service Request/Comments (OPTIONAL)
                    </label>

                    <textarea
                      [(ngModel)]="data.other_service"
                      id="other_service" name="other_service"
                      type="text"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    ></textarea>


                </div>

                <br />
                <a (click)="addBooking(transportResForm)"
                class="inline-block text-center w-full bg-[#D5B634] px-2 py-3 font-medium text-white shadow lg:px-14 lg:py-4 text-sm lg:text-base"
                routerLink="/transportation"
              >
                Get Quote
              </a>
                <!-- <button
                  type="submit"
                  class="inline-block bg-[#d5b634] py-4 font-medium text-white w-full hover:bg-[#171717] border hover:text-white transition-all ease-linear border-[#d5b634] uppercase hover:border-[#171717]"
                >
                  Get A Quote
                </button> -->
              </form>
            </div>
          </div>
        </section>
        <!-- End Insert Form -->
      </div>
    </div>
  </section>
  <section>
    <div
      class="max-w-screen-2xl px-4 sm:px-6 lg:px-8 mx-auto text-[#171717]"
    >
      <div class="lg:w-1/2 lg:-mt-44 ml-3.5">
        <h2 class="text-xl mb-1 -ml-3.5">Additional Information</h2>

        <ul class="text-lg font-light list-disc">
          <li>Confirmation will be received at time of booking</li>
          <li>Child rate may apply</li>
          <li>Children must be accompanied by an adult</li>
         </ul>
      </div>

      <div class="lg:w-1/2 ml-3.5 mt-10">
        <h2 class="text-xl mb-1 -ml-3.5 text-red-600">
          Cancellation Policy
        </h2>

        <ul class="text-lg font-light list-disc">
          <li>
            All sales are final and incur 100% cancellation penalties.
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section>
    <div class="py-16 mx-auto mb-20 max-w-screen-2xl">
      <div
        class="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 text-[#171717] text-center"
      >
        <div class="p-8" href="/services/digital-campaigns">
          <h2 class="text-xl font-semibold uppercase">
            Hotels in Ocho Rios
          </h2>

          <ul class="mt-1">
            <li>Transportation O/Way:
            </li>
              <li>Transportation Two Way</li>
              <div class="mt-2 md:mt-4">
                  <a
                    routerLink="/get-quote"
                    class="mt-2 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-6 py-4 text-white hover:bg-transparent hover:text-[#d5b634] ease transition-all"
                  >
                    <span class="text-sm font-medium open-sans">
                      Get A Quote
                    </span>
                  </a>
                </div>
          </ul>
        </div>
        <div class="p-8" href="/services/digital-campaigns">
          <h2 class="text-xl font-semibold uppercase">
            Hotels in Kingston
          </h2>

          <ul class="mt-1">
            <li>Transportation O/Way</li>
            <li>Transportation Two Way</li>
              <div class="mt-2 md:mt-4">
                  <a
                    routerLink="/get-quote"
                    class="mt-2 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-6 py-4 text-white hover:bg-transparent hover:text-[#d5b634] ease transition-all"
                  >
                    <span class="text-sm font-medium open-sans">
                      Get A Quote
                    </span>
                  </a>
                </div>

          </ul>
        </div>
        <div class="p-8" href="/services/digital-campaigns">
          <h2 class="text-xl font-semibold uppercase">
            Hotels in Montego Bay
          </h2>

          <ul class="mt-1">
            <li>Transportation O/Way</li>
            <li>Transportation Two Way</li>
               <div class="mt-2 md:mt-4">
                  <a
                    routerLink="/get-quote"
                    class="mt-2 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-6 py-4 text-white hover:bg-transparent hover:text-[#d5b634] ease transition-all"
                  >
                    <span class="text-sm font-medium open-sans">
                      Get A Quote
                    </span>
                  </a>
               </div>
          </ul>
        </div>
      </div>
    </div>
  </section>
</main>
