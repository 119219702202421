import { User } from './../models/user';
import { APIResponse } from './../models/api-response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorService } from './error.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private API_URL = environment.api_url + '/api/v1/users';

    currentUser?: User;
    getCurrentUser(cb?: () => void) {
      console.log(`Inside getCurrentUser`);
        this.getProfileInformation().subscribe(res => {
          console.log(`Get Profile RESP: ${res.status}`);
            if (res.status === 'success') {
                this.currentUser = res.data!['user'];
                this.setDashboardMode();
                if (cb) cb();
            }
        });
    }
    //============================================================
    //Define Roles

    isEmployee(): boolean {
        return this.currentUser?.user_role === ('admin' || 'csrrep' || 'driver') ;
    }

    isAgent(): boolean {
        return this.currentUser?.user_role === 'agent';
    }

    isCustomer(): boolean {
        return this.currentUser?.user_role === 'customer';
    }

    // ============================================================
    setDashboardMode() {
      console.log(`Router Config: ${this.router.config}`)
        const i = this.router.config.findIndex(r => r.path === 'dashboard');
        this.router.config.splice(i, 1);
        this.router.config.unshift(
            {
                path: 'dashboard',
                loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
            }
        )
    }



    constructor(private http: HttpClient, private router: Router, private errorService: ErrorService) { }

    getProfileInformation(): Observable<APIResponse<User>> {
        return this.http.get<APIResponse<User>>(this.API_URL + '/profile').pipe(catchError(this.errorService.handleHttpError));
    }

    updateAccountInformation(data: any): Observable<APIResponse> {
        return this.http.patch<APIResponse>(this.API_URL + '/profile', data).pipe(catchError(this.errorService.handleHttpError));
    }

    updatePassword(data: any): Observable<APIResponse> {
        return this.http.patch<APIResponse>(this.API_URL + '/profile/password', data).pipe(catchError(this.errorService.handleHttpError));
    }

    disableAccount(): Observable<APIResponse<null>> {
        return this.http.delete<APIResponse<null>>(this.API_URL + '/profile').pipe(catchError(this.errorService.handleHttpError));
    }


    // ADMIN ONLY
    getAllUsers(): Observable<APIResponse<User[]>> {
        return this.http.get<APIResponse<User[]>>(this.API_URL).pipe(catchError(this.errorService.handleHttpError));
    }

    getUserById(id: number): Observable<APIResponse> {
        return this.http.get<APIResponse>(`${this.API_URL}/${id}`).pipe(catchError(this.errorService.handleHttpError));
    }

    createUser(data: any): Observable<APIResponse> {
        return this.http.post<APIResponse>(this.API_URL, data).pipe(catchError(this.errorService.handleHttpError));
    }

    // resendWelcomeEmail(data: { email_address: string }): Observable<APIResponse> {
    //     return this.http.post<APIResponse>(this.API_URL + '/resend-welcome', data).pipe(catchError(this.errorService.handleHttpError));
    // }

    updateUser(id: number, data: any): Observable<APIResponse> {
        return this.http.patch<APIResponse>(this.API_URL + '/' + id, data).pipe(catchError(this.errorService.handleHttpError));
    }

    // ADMIN ONLY
    deleteUser(id: number): Observable<APIResponse> {
        return this.http.delete<APIResponse>(this.API_URL + '/' + id).pipe(catchError(this.errorService.handleHttpError));
    }
}
