import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appFormatPhone]'
})
export class FormatPhoneDirective {

    constructor() { }


    // Pnone number auto Hyphen
    @HostListener('input', ['$event.target', '$event.data', '$event.inputType'])
                    onValueChanged(input: HTMLInputElement, data: string, inputType: string) {
        const isDeleting = inputType.includes('deleteContent');
        if (!data && !isDeleting) return;

        if (Number.isInteger(+data) || isDeleting) {
            const digits = input.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            const first = digits.substring(0, 3);
            const middle = digits.substring(3, 6);
            const last = digits.substring(6, 10);

            if (digits.length > 6) { input.value = `(${first})${middle}-${last}`; }
            else if (digits.length > 3) { input.value = `(${first})${middle}`; }
            else if (digits.length > 0) { input.value = `(${first}`; }
            else input.value = '';
        }
        else {
            input.value = input.value.slice(0, -1);
        }

    }
}
