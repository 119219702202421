import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {NgForm} from '@angular/forms';
import { Router } from '@angular/router';
import {Subscription} from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.css']
})

export class LoginPage implements OnInit, OnDestroy {
  isResettingPassword: boolean = false;
  resetPasswordSent: boolean = false;

  loading: boolean = false;

  data = {
      email_address: null,
      password: null
  };

  private subscriptions = new Subscription();

  @ViewChild("form") form?: NgForm;
  get email_address() {
      return this.form?.controls['email_address'];
  }
  get password_control() {
      return this.form?.controls['password'];
  }

  @ViewChild("forgotForm") forgotForm?: NgForm;
  get forgot_email() {
      return this.forgotForm?.controls['email'];
  }



  constructor(private authService:AuthService,
              private userService: UserService,
              private router:Router) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.unsubscribe();
  }
  //Toggle Between Reset Password & Login Form
  toggleForgotForm() {
    this.isResettingPassword = !this.isResettingPassword;
    this.loading = false;
 }

 handleSubmit(): void {
  this.loading = true;

  const loginSub = this.authService.login(this.data).subscribe((loginRes) => {
    console.log('Before Login Call ->');

      if (loginRes.status === 'success') {
        console.log(loginRes);
          this.authService.authToken = loginRes.data!['token'];
          //Fetch Role
          console.log(`ROLE: ${loginRes.data!['user'].user_role}`);

          if (loginRes.data!['user'].user_role === 'agent') {
              const statusSub = this.authService.getAccountStatuses().subscribe(res => {
                  if (res.status === 'success') {
                      const account_status = res.data!['account_status'];
                      this.authService.accountStatus = {
                          confirmed: !!account_status.email_verification,
                          hasMemberDetails: !!account_status.submitted_profile_det,
                      }

                      if (account_status.email_verification !== 1) {
                        this.router.navigateByUrl('/awaiting-confirmation/verified=false');
                          // const confirmSub = this.authService.resendConfirmation(loginRes.data!['verificationToken']).subscribe(res => {
                          //     if (res.status === 'success') {
                          //     }
                          //   });
                          //   this.subscriptions.add(confirmSub);
                            this.loading = false;
                      } else if (account_status.submitted_profile_det !== 1) {
                          this.router.navigate(['/complete-signup', loginRes.data!['verificationToken']]);
                      } else {
                          this.userService.getCurrentUser(() => {
                              this.authService.isLoggedIn$.next(true);
                              this.authService.isLoggedIn = true;
                              this.authService.saveAuthToken();
                              this.authService.saveAccountStatus();
                              this.router.navigateByUrl('/dashboard');
                          });
                          this.loading = false;
                      }
                  } else {
                      this.loading = false;
                  }
              });
              this.subscriptions.add(statusSub);
          } else {
            console.log(`Admin Detected: `);
              this.authService.saveAuthToken();
              console.log(`After saveAuthToken: `);
              this.userService.getCurrentUser(() => {
                  this.authService.isLoggedIn$.next(true);
                  this.authService.isLoggedIn = true;
                  this.router.navigateByUrl('/dashboard');
              });
              this.loading = false;
          }
      } else {
          this.loading = false;
      }

      if (loginRes.status === 'fail') {
          if (loginRes.message?.toLowerCase().includes('email')) {
              this.form?.controls['email_address'].setErrors({
                  custom: loginRes.message
              });
          }

          if (loginRes.message?.toLowerCase().includes('password')) {
              this.form?.controls['password'].setErrors({
                  custom: loginRes.message
              });
          }
      }
  });
  this.subscriptions.add(loginSub);
}

sendResetEmail() {
  this.loading = true;
  const forgotSub = this.authService.forgotPassword({ email_address: this.data.email_address }).subscribe(res => {
      if (res.status === 'success') this.resetPasswordSent = true;
       //console.log(`HashToken: ${res.data!['hashToken']} -:- UserID: ${res.data!['user_id']}`)
      if (res.status === 'fail') {
          this.forgot_email?.setErrors({ custom: res.message });
      }
      // if (res.status === 'fail') {
      //     this.forgot_email?.setErrors({ custom: res.message });
      // }

      this.loading = false;
  });

  this.subscriptions.add(forgotSub);
}

}
