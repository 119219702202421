import { trigger, transition, group, query, style, animate } from "@angular/animations";

export const popoutAnimation = trigger('routeAnimations', [
    transition('hide => show', [
        group([
            query('.wrapper', [
                style({ opacity: 0, transform: 'translateX(100%)' }),
                animate('0.2s ease-out',
                    style({ opacity: 1, transform: 'translateX(0)' }))
            ], { optional: true }),
            query('.overlay', [
                style({ opacity: 0 }),
                animate('0.3s ease-out',
                    style({ opacity: 1 }))
            ], { optional: true }),
        ]),
    ]),
    transition('show => hide', [
        group([
            query('.wrapper', [
                style({ opacity: 1, transform: 'translateX(0)' }),
                animate('0.2s ease-in',
                    style({ opacity: 0, transform: 'translateX(100%)' }))
            ], { optional: true }),
            query('.overlay', [
                style({ opacity: 1 }),
                animate('0.3s ease-out',
                    style({ opacity: 0 }))
            ], { optional: true }),
        ])
    ]),
]);
