import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import {NgForm} from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.css']
})
export class RegisterPage implements OnInit, OnDestroy {
  loading: boolean = false;

  data = {
      frst_nm: null,
      last_nm: null,
      email_address: null,
      password: null,
      confirm: null,
  }

  @ViewChild("form") form?: NgForm;

  get frst_nm() {
      return this.form?.controls['first_name'];
  }
  get last_nm() {
      return this.form?.controls['last_name'];
  }
  get email_control() {
      return this.form?.controls['email_address'];
  }
  get password_control() {
      return this.form?.controls['password'];
  }
  get confirm_control() {
      return this.form?.controls['confirm'];
  }

  get passwordMatches(): boolean {
      return this.password_control?.value === this.confirm_control?.value;
  }


  private subscriptions = new Subscription();

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  handleSubmit(): void {
    this.loading = true;
    const registerSub = this.authService.register(this.data).subscribe((res) => {
        if (res.status === 'success') {
            //console.log(`RESPONSE :- ${res.data!['url']}`);
            // this.router.navigateByUrl(`${res.data!['url2']}`);
            this.router.navigateByUrl('/awaiting-confirmation');
        }

        if (res.status === 'fail') {
            if (res.message?.toLowerCase().includes('email address')) {
                this.email_control?.setErrors({
                    custom: res.message,
                })
            }
        }

        this.loading = false;
    });
    this.subscriptions.add(registerSub);
}
}
