<main class="min-h-screen flex flex-col">
  <div class="mx-auto pb-10 pt-5 sm:w-2/5">
    <span
      class="inline-flex overflow-hidden bg-white shadow-sm justify-center w-full lg:text-lg nunito-sans"
    >
      <button *ngIf="tabYacht; else tabYachtIA"
        (click)="toggleTab('YACHT')"
        class="inline-block w-1/2 py-3 px-5 font-medium bg-[#d5b634] border border-[#d5b634]">
        Yacht Rental
      </button>
        <ng-template #tabYachtIA>
            <button
                (click)="toggleTab('YACHT')"
                class="inline-block px-5 py-3 font-medium text-[#171717] w-1/2 hover:text-white hover:bg-[#171717] transition-all ease-in border-white border-r border-t border-b border-l">
                Yacht Rental
          </button>
        </ng-template>
      <button *ngIf="tabEnt; else tabEntIA"
        (click)="toggleTab('ENT')"
        class="inline-block w-1/2 py-3 px-5 font-medium bg-[#d5b634] border border-[#d5b634]">
        Entertainment
      </button>
          <ng-template #tabEntIA>
            <button
                (click)="toggleTab('ENT')"
                class="inline-block px-5 py-3 font-medium text-[#171717] w-1/2 hover:text-white hover:bg-[#171717] transition-all ease-in border-white border-r border-t border-b">
                Entertainment
          </button>
        </ng-template>

    </span>
  </div>
  <section>
    <div class="w-full lg:h-[650px]">
      <img *ngIf="tabYacht"
        src="/assets/images/yacht_rental.jpg"
        alt=""
        class="w-full h-full object-cover object-center"
      />
          <img *ngIf="tabEntIA"
          src="/assets/images/pier1.jpg"
          alt=""
          class="w-full h-full object-cover object-center"
        />
    </div>
  </section>

  <!-- Yacht  -->
  <section *ngIf="tabYacht" class="bg-[#ececec]">
    <div
      class="mx-auto max-w-screen-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8"
    >
      <div class="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
        <div>
          <div class="relative overflow-hidden">
            <video class="h-full w-full object-cover" controls>
              <source
                src="/assets/videos/yacht_rental.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <br />
          <div class="lg:py-16 nunito-sans text-[#171717] lg:text-lg">
            <p>
              Imagine a cocktail in your hand, the Caribbean sea breeze
              caressing your skin as the sun is setting on the horizon. All
              while being serenaded by the sounds of Jamaica on a private
              yacht. Enjoy the views of Jamaica from the oceanside. Discover
              magical beaches that have access to waterfalls that are only
              accessible by water. This is just a hint if perfection that
              awaits you on the water. Crews are on standby for all your
              needs so that you can relax enjoy & rejuvenate.
            </p>
          </div>
        </div>

        <section >
          <div class="container px-5 mx-auto">
            <div class="flex flex-col text-center w-full mb-12">
              <h1
                class="sm:text-3xl text-2xl font-medium title-font mb-4 0 lora lg:text-left text-[#171717]"
              >
                Book Yacht Now!
              </h1>
            </div>
            <div  class="w-full pb-24">
              <form action="" class="mt-8 mb-0 max-w-2xl space-y-4">
                <div class="flex flex-wrap -m-2 gap-y-5">
                  <!-- location -->
                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      for="UserEmail"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Pick-up Location
                    </label>

                    <select
                      name=""
                      id=""
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    >
                      <option value="" disabled selected>
                        Select a pick-up location
                      </option>
                    </select>
                  </div>
                  <!-- date -->

                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Departure Date
                    </label>

                    <input
                      type="datetime-local"
                      id="date"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>
                  <br />

                  <!-- yacht size -->

                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Yacht Size
                    </label>

                    <select
                      name=""
                      id=""
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    >
                      <option value="" selected>30 Footers</option>
                    </select>
                  </div>

                  <!-- no. of persons -->

                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      No. of Persons (including children)
                    </label>

                    <input
                      type="number"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>
                </div>

                <br />

                <button
                  type="submit"
                  class="inline-block bg-[#d5b634] py-4 font-medium text-white w-full hover:bg-[#171717] border hover:text-white transition-all ease-linear border-[#d5b634] uppercase hover:border-[#171717]"
                >
                  Get A Quote
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <!-- End Yacht -->

  <!-- Entertainment  -->
  <section *ngIf="tabEnt" class="bg-[#ececec]">
    <div
      class="mx-auto max-w-screen-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8"
    >
      <div class="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
        <div>
          <div class="relative overflow-hidden">
            <video class="h-full w-full object-cover" controls>
              <source
                src="/assets/videos/pier1.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <br />
          <div class="lg:py-16 nunito-sans text-[#171717] lg:text-lg">
            <p>
              The nightlife of Jamaica is some of the best in the
              Caribbean! Different nights different sights and
              that's where we come in. Not only will you be
              chauffeured to one of the hottest clubs in
              Jamaica but we will reserve you a section so that
              you and your guests can just party all night long
              with out interruption. Our drivers will be with
              you throughout the night so if you want to leave
              and go to another party spot we got you.
            </p>
          </div>
        </div>

        <section >
          <div class="container px-5 mx-auto">
            <div class="flex flex-col text-center w-full mb-12">
              <h1
                class="sm:text-3xl text-2xl font-medium title-font mb-4 0 lora lg:text-left text-[#171717]"
              >
                Book Entertainment Now!
              </h1>
            </div>
            <div  class="w-full pb-24">
              <form action="" class="mt-8 mb-0 max-w-2xl space-y-4">
                <div class="flex flex-wrap -m-2 gap-y-5">
                  <!-- Name -->
                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      for="pickup-location"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Name
                    </label>

                    <input
                      id="pickup-details"
                      name="pickup-details"
                      type="text"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]" />
                    </div>
                  <!-- Email -->
                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      for="pickup-location"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Email Address

                    </label>

                    <input
                      id="email"
                      name="email"
                      type="email"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]" />
                    </div>
                  <!-- location -->
                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      for="pickup-location"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Pick-up Location
                    </label>

                    <select
                      name="pickup-location"
                      id="pickup-location"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    >
                      <option value="" disabled selected>Select a pick-up location</option>
                      <option value="hotel-montegobay">Hotel In Montego Bay</option>
                      <option value="villa-montegobay">Vila In Montego Bay</option>
                      <option value="hotel-negril">Hotel In Negril</option>
                      <option value="villa-negril">Villa In Negril</option>
                      <option value="other">Other Location</option>
                    </select>
                  </div>

                  <!-- Pickup Location Details -->
                  <div class="p-2 w-full lg:w-1/2">
                    <label class="block font-medium text-[#171717] nunito-sans" >
                     Pickup Details (Name(Hotel)/Address(Villa))
                    </label>
                    <input
                      id="pickup-details"
                      name="pickup-details"
                      type="text"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>


                  <!-- Entertainment Choice -->
                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      for="ent-option"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Entertainment Option
                    </label>
                    <select
                      name="ent-option"
                      id="ent-option"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    >
                      <option value="" disabled selected>Select an Entertainment</option>
                      <option value="pier1">Pier I</option>
                      <option value="margaritaville">Margaritaville</option>
                      <option value="club-hopper">Club Hopper</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>

                  <!-- date -->
                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Pickup Date
                    </label>

                    <input
                      type="datetime-local"
                      id="date"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>
                  <br />

                  <!-- no. of persons -->
                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      No. of Persons (including children)
                    </label>

                    <input
                      type="number"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>
                </div>

                <br />

                <button
                  type="submit"
                  class="inline-block bg-[#d5b634] py-4 font-medium text-white w-full hover:bg-[#171717] border hover:text-white transition-all ease-linear border-[#d5b634] uppercase hover:border-[#171717]"
                >
                  Get A Quote
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <!-- End Entertainment -->


</main>
