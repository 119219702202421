import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-awaiting-confirmation',
  templateUrl: './awaiting-confirmation.page.html',
  styleUrls: ['./awaiting-confirmation.page.css']
})
export class AwaitingConfirmationPage implements OnInit {
  isNotYetConfirmed: boolean = false;
  constructor(private route:ActivatedRoute) { }

  ngOnInit(): void {
    //if()
    this.route.queryParams
        .subscribe(params => {
          if(params?.['verified'] == false){
            this.isNotYetConfirmed = true;
          }
        });
  }

}
