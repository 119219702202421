//import {AnimationOptions}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//=====PAGES
import { LoginPage } from './login/login.page';
import { RegisterPage } from './register/register.page';
import { ResetPasswordPage } from './reset-password/reset-password.page';
import { ConfirmAccountPage } from './confirm-account/confirm-account.page';
import { CompleteSignupPage } from './complete-signup/complete-signup.page';
import { AwaitingConfirmationPage } from './awaiting-confirmation/awaiting-confirmation.page';

import { RequiresAuthGuard } from '../shared/guards/requires-auth.guard';
import { UnverifiedOnlyGuard } from '../shared/guards/unverified-only.guard';
import { RequiresUnauthGuard } from '../shared/guards/requires-unauth.guard';
//==========================

const routes: Routes = [
  { path: 'login', component: LoginPage, canActivate: [] },

  { path: 'register', component: RegisterPage, canActivate: [] },

  { path: 'awaiting-confirmation', component: AwaitingConfirmationPage, canActivate: [] },

  { path: 'confirm-account/:verification_token/:token', component: ConfirmAccountPage, canActivate: [] },

  { path: 'complete-signup/:verification_token', component: CompleteSignupPage },

  { path: 'reset-password/:verification_token/:token', component: ResetPasswordPage },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
