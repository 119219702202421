<main class="min-h-screen flex flex-col">
  <section>
    <div
      class="container mx-auto flex px-5 py-10 items-center justify-center flex-col"
    >
      <div class="text-center lg:w-2/3 w-full relative mt-16 md:mt-32">
        <h1
          class="title-font sm:text-8xl text-6xl mb-4 font-medium lora uppercase"
        >
          Login
        </h1>
        <div
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-20 md:h-60 w-1 bg-[#d5b634] -z-10"
        ></div>
      </div>

      <div class="mx-auto w-full px-4 py-20 sm:px-6 lg:px-8">
        <form  class="mx-auto mt-4 mb-0 max-w-md space-y-4"
          *ngIf="!isResettingPassword; else forgot"
            (ngSubmit)="form.valid && handleSubmit()" #form="ngForm">
          <div>
            <label
              for="email_address"
              class="block font-medium text-[#171717] nunito-sans"
            >
              Email
            </label>

            <input
              [(ngModel)]="data.email_address"
              type="email"
              id="email_address"
              name="email_address"
              placeholder="joghndoe@luxurystartravels.com"
              class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
            />
            <div class="relative h-6">
              <error-display class="absolute"
                  *ngIf="(form.submitted || email_address?.touched) && email_address?.errors?.['required']">
                  Please enter your email address
              </error-display>
              <error-display class="absolute"
                  *ngIf="(form.submitted || email_address?.touched) && email_address?.errors?.['email']">
                  Not a valid email address
              </error-display>
              <error-display class="absolute" *ngIf="email_address?.errors?.['custom']">
                  {{email_address!.errors?.['custom']}}
              </error-display>
          </div>
          </div>
          <div class="form-control mb-6">
            <div class="flex items-end justify-between">
              <label for="password"  class="block font-medium text-[#171717] nunito-sans" >
                <span>Password</span>
              </label>
              <a class="text-yellow-800 hover:underline text-base cursor-pointer" (click)="toggleForgotForm()">
                Forgot password?
            </a>
            </div>
            <input
              type="password"
              id="password" name="password" [(ngModel)]="data.password" required
              class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"/>
              <div class="relative h-6">
                <error-display class="absolute"
                    *ngIf="(form.submitted || password_control?.touched) && password_control?.errors?.['required']">
                    Please enter your password
                </error-display>
                <error-display class="absolute" *ngIf="password_control?.errors?.['custom']">
                    {{password_control!.errors?.['custom']}}
                </error-display>
            </div>

          </div>
          <p class="max-lg:text-center">Not yet a member? <a class="text-yellow-500 hover:underline"
            routerLink="/register">Create an account</a>
        </p>

          <br />
          <button
            type="submit"
            class="inline-block bg-[#d5b634] py-4 font-medium text-white w-full"
          >
            Sign in
            <base-loading-spinner class="fill-white text-gray-600" *ngIf="loading"></base-loading-spinner>
          </button>
        </form>
        <ng-template #forgot>

        </ng-template>
      </div>
    </div>
  </section>
</main>
