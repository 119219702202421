import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { QueryFilters } from 'src/app/shared/models/queryfilter';
import { Tour } from 'src/app/shared/models/tour';
import { TourService } from 'src/app/shared/services/tour.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.css']
})
export class ToursComponent implements OnInit {

  loading: boolean = false;
  api_url: string = environment.api_url ;
  tours: Tour[] = [];

  private _subscriptions = new Subscription();

  // Package Filters
  filters: QueryFilters = {
      page: 1,
      limit: 6,

      field: 'hs_description',
  };

  maxPages = 1;
  goToPage(newPage: number) {
      this.filters.page = (newPage <= 0) ? 1 : (newPage > this.maxPages) ? this.maxPages : newPage;
      this.getAllTours();
  }

  //Fetch All Tours
  private getAllTours(): void {
    this.loading = true;
    const tourSub = this.tourService.getAllTours(this.filters).subscribe(res => {
        if (res.status === 'success') {
            this.tours = res.data!['tours'];
            console.log(this.tours)
            this.maxPages = res.numOfPages || 1;
        }

        this.loading = false;
    });
    this._subscriptions.add(tourSub);
}
  constructor(private tourService: TourService, private uiService: UiService) { }

  ngOnInit(): void {
    this.getAllTours();
  }

}
