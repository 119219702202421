import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  showFooter: boolean = true;
  isLoggedIn: boolean = false;

  constructor(private uiService: UiService, private authService: AuthService, private router: Router) {
    this.authService.isLoggedIn$.subscribe(val => {
        this.isLoggedIn = val;
    });
    this.uiService.showFooter$.subscribe((val) => this.showFooter = val);

}


  ngOnInit(): void {
  }

}
