import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lifestyle',
  templateUrl: './lifestyle.component.html',
  styleUrls: ['./lifestyle.component.css']
})
export class LifestyleComponent implements OnInit {
  tabYacht: boolean = true;
  tabEnt: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.toggleTab('YACHT');
  }

  toggleTab(vTab: string): void {
    switch (vTab){
      case 'ENT':{
          this.tabEnt = true;
          this.tabYacht = false;
          break;
      }
      case 'YACHT':{
          this.tabEnt = false;
          this.tabYacht = true;
          break;
      }
      default:{
          this.tabEnt = false;
          this.tabYacht = true;
          break;
      }
    }
  }

}
