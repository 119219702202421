import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponse } from '../models/api-response';
import { AccountStatus } from '../models/user';
import { ErrorService } from './error.service';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private API_URL = environment.api_url + '/api/v1/users';

    public authToken?: string;
    public accountStatus?: AccountStatus;
    isLoggedIn$ = new Subject<boolean>();
    isLoggedIn?: boolean;
    // isLoggedIn2$ = new BehaviorSubject<boolean>(false);

    private _saveToStorage(key: string, value: any) {
        localStorage.setItem(key, value);
    }

    saveAuthToken(): void {
        this._saveToStorage('authToken', this.authToken);
    }

    saveAccountStatus(): void {
        this._saveToStorage('accountStatus', JSON.stringify(this.accountStatus));
    }

    autoLogin() {

        const tokenFromStorage = localStorage.getItem('authToken');
        this.authToken = tokenFromStorage || undefined;

        const accountStatusFromStorage = localStorage.getItem('accountStatus');
        this.accountStatus = accountStatusFromStorage ? JSON.parse(accountStatusFromStorage) : undefined;

        if (this.authToken) {
            this.userService.getCurrentUser(() => {
                this.isLoggedIn$.next(true);
                this.isLoggedIn = true;
                // this.isLoggedIn2$.next(true);
            });
        } else {
            this.isLoggedIn$.next(false);
            this.isLoggedIn = false;
        }

    }

    constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    }

    login(data: any): Observable<APIResponse> {
        return this.http.post<APIResponse>(this.API_URL + '/login', data).pipe(catchError(this.errorService.handleHttpError));
    }

    register(data: any): Observable<APIResponse> {
        return this.http.post<APIResponse>(this.API_URL + '/register', data).pipe(catchError(this.errorService.handleHttpError));
    }

    // confirm(token: string): Observable<APIResponse> {
    //     return this.http.get<APIResponse>(this.API_URL + `/confirm/${token}`).pipe(catchError(this.errorService.handleHttpError));
    // }

    // resendConfirmation(token: string | number, ignoreEncryption: boolean = false): Observable<APIResponse> {
    //     return this.http.get<APIResponse>(this.API_URL + '/resend-confirmation/' + token + `?ignoreEncryption=${ignoreEncryption ? 'true' : 'false'}`).pipe(catchError(this.errorService.handleHttpError));
    // }

    getAccountStatuses(): Observable<APIResponse> {
        return this.http.get<APIResponse>(this.API_URL + `/account-statuses`).pipe(catchError(this.errorService.handleHttpError));
    }

    // completeSignup(token: string | number, data: any): Observable<APIResponse> {
    //     return this.http.post<APIResponse>(this.API_URL + '/complete-signup/' + token, data).pipe(catchError(this.errorService.handleHttpError));
    // }

    forgotPassword(data: any): Observable<APIResponse> {
        return this.http.post<APIResponse>(this.API_URL + '/forgot-password', data).pipe(catchError(this.errorService.handleHttpError));
    }

    resetPassword(token: string, data: any): Observable<APIResponse> {
        return this.http.patch<APIResponse>(this.API_URL + `/reset-password/${token}`, data).pipe(catchError(this.errorService.handleHttpError));
    }

    logout(): void {
        this.authToken = undefined;
        this.accountStatus = undefined;

        localStorage.removeItem('authToken');
        localStorage.removeItem('accountStatus');

        this.isLoggedIn$.next(false);
        this.isLoggedIn = false;
    }

}
