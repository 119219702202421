import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'base-paginator',
    template: `
    <div class="xs:flex-row xs:justify-between flex flex-col items-center px-5 py-5 bg-white border-t">
        <div class="xs:mt-0 inline-flex gap-2 mt-2">
            <button [ngClass]="{'invisible':currentPage === 1}"
                (click)="goToPage(currentPage! - 1)"
                class="text-indigo-50 hover:bg-blue-700 primary-bg px-4 py-2 text-sm font-semibold transition duration-150 rounded-l">
                <i class="fa-solid fa-arrow-left"></i>
                Prev
            </button>
            <button *ngFor="let page of pageButtons | slice:lowerButtonLimit:upperButtonLimit"
                class="hover:text-indigo-50 hover:bg-blue-700 border-secondary-500 px-4 py-2 text-sm font-semibold transition duration-150 border-2"
                [ngClass]="{'primary-bg text-white': page === currentPage, 'bg-white':page !== currentPage}" (click)="goToPage(page)">
                {{page}}
            </button>
            <button [ngClass]="{'invisible':currentPage === maxPages}"
                (click)="goToPage(currentPage! + 1)"
                class="text-indigo-50 hover:bg-blue-700 primary-bg px-4 py-2 text-sm font-semibold transition duration-150 rounded-r">
                Next
                <i class="fa-solid fa-arrow-right"></i>
            </button>
        </div>
    </div>
  `,
    styles: [
    ]
})
export class PaginatorComponent implements OnInit {

    @Input('maxPages') maxPages: number = 0;
    @Input('currentPage') currentPage: number = 0;
    @Output('pageChange') onPageChange = new EventEmitter<number>();

    constructor() { }

    buttonLimit: number = 5;
    get lowerButtonLimit(): number {
        return Math.abs(this.currentPage / this.buttonLimit) < 1 ? 0 : this.currentPage - 3;
    }
    get upperButtonLimit(): number {
        return Math.min(this.maxPages, Math.abs(this.currentPage / this.buttonLimit) < 1 ? this.buttonLimit : this.currentPage + 2);
    }

    get pageButtons(): number[] {
        return new Array(this.maxPages).fill(null).map((v, i) => i + 1);
    }
    goToPage(newPage: number) {
        this.onPageChange.emit(newPage);
    }

    ngOnInit(): void {
    }

}
