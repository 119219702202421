import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'base-popout',
    template: `
    <div class="isolate">
        <div class="overlay fixed inset-0 bg-black z-[60] bg-opacity-50" (click)="exit()"></div>
        <div class="wrapper fixed inset-0 left-auto w-full z-[60] max-w-lg overflow-y-auto bg-white">
            <div class="w-full py-2 bg-white sticky top-0 left-0">
            <button class="" (click)="exit()">

                <div
                    class="aspect-square text-primary-500 absolute top-4 right-4 hover:bg-gray-100 px-4 py-2 text-xl transition rounded-full">
                    <i class="fa-solid fa-close"></i>
                </div>

            </button>
            </div>
            <div class="p-12">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
  `,
    styles: []
})
export class PopoutComponent implements AfterViewInit {

    constructor(private route: ActivatedRoute, private router: Router) { }

    exit() {
        this.router.navigate(['./'], { relativeTo: this.route.parent });
    }

    ngAfterViewInit(): void {
    }
}
