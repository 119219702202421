<main class="min-h-screen flex flex-col">
  <!-- heading -->
  <section>
    <div
      class="container mx-auto flex items-center justify-center flex-col"
    >
      <div class="text-center lg:w-2/3 w-full relative mt-16 md:mt-32">
        <div
          class="absolute left-1/2 transform top-10 -translate-x-1/2 h-72 lg:h-96 w-1 bg-[#d5b634] -z-10"
        ></div>
        <h1
          class="title-font md:text-9xl text-6xl mb-4 font-medium lora uppercase"
        >
          Mission
        </h1>
        <!-- details -->
        <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div class="grid grid-cols-2 gap-8 lg:gap-16">
            <div class="lg:py-24">
              <div
                class="text-xs sm:text-sm md:text-base lg:text-lg -mt-5 lg:-mt-20 text-right"
              >
                We have all heard the Bob Marley adage, "Don't worry about a
                thing, cause every little thing is gonna be alright!" This
                is Luxury Star Travels approach to your time in Jamaica with
                us.
              </div>
            </div>
            <div class="pt-16">
              <p
                class="nunito-sans text-xs sm:text:sm md:text-base lg:text-lg text-left"
              >
                Built on the premise of first class, we cater to our guest
                in the form of luxury transportation, breathtaking
                villa/resorts and exceptional concierge services that will
                help you experience Jamaica like no other. Jamaica's beauty
                lies in it's people, land and culture which embodies an
                unforgettable time.
              </p>
            </div>
            <div class="lg:-py-24 col-span-2">
              <p
                class="nunito-sans text-xs sm:text:sm md:text-base lg:text-lg"
              >
                We here at Luxury Star are very lucky to experience this
                with you. "Up to the times, Down to the minute!"
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-[#ececec] body-font relative min-h-screen">
    <section class="text-gray-600 body-font">
      <div
        class="container mx-auto flex py-24 items-center justify-center flex-col"
      >
        <video class="w-3/5 object-cover" controls poster="/assets/images/mission-02.png">
          <source src="/assets/videos/mission.mp4" type="video/mp4" />
        </video>
        <div class="text-center lg:w-2/3 w-full mt-20">
          <h1
            class="title-font sm:text-3xl text-2xl mb-4 font-medium text-gray-900 lora"
          >
            Our Curated Services
          </h1>

          <div
            class="flex justify-between text-left mt-20 px-8 flex-wrap nunito-sans text-lg"
          >
            <ul class="list-disc leading-10">
              <li>Chauffeured Arrival and Departure from airport</li>
              <li>Yacht Rental</li>
              <li>Private Villa/ Mansion Rental</li>
            </ul>
            <ul class="list-disc leading-10">
              <li>Private VIP excursions</li>
              <li>Private Driver for your entire stay</li>
              <li>Private Chef</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </section>
</main>
