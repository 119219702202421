<main>
  <!-- hero -->

  <section class="flex flex-col-reverse sm:flex-row lora">
    <div class="w-full text-sm sm:w-5/12 lg:w-1/4">
      <nav
        class="flex flex-col font-medium text-center uppercase border-gray-200"
      >
        <a routerLink="/transportation" class="px-6 py-5 bg-[#D5B634] ease transition-all">
          Transportation
        </a>
        <a routerLink="/fleet" class="px-6 py-5 hover:bg-[#D5B634] ease transition-all">
          Luxury Concierge
        </a>
        <a routerLink="/fleet" class="px-6 py-5 hover:bg-[#D5B634] ease transition-all">
          Fast Track Service
        </a>

      </nav>
    </div>
    <div class="w-full max-h-[700px]">
      <img *ngIf="! mediaVideo"
        src="/assets/hero.jpeg"
        alt=""
        class="object-cover object-center w-full h-full"
      />
      <video *ngIf="mediaVideo" class="object-cover w-full h-full" controls autoplay loop>
        <source
          src="/assets/videos/escalade.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  </section>

  <!-- Tours -->

  <section>
    <div class="px-4 py-16 mx-auto sm:px-6 lg:px-16">
      <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
        <div class="overflow-hidden lg:order-last lg:h-full">
          <video class="object-cover w-full h-full" controls>
            <source src="/assets/videos/homepage.mov" type="video/mp4" />
          </video>
        </div>

        <div class="pt-24 text-center sm:text-left">
          <div>
            <h2 class="text-3xl font-medium sm:text-4xl lora">
              Tours
              <div class="w-16 bg-white h-1.5 mt-4 mx-auto sm:mx-0"></div>
            </h2>
          </div>

          <p class="mx-auto mt-4 lg:w-3/4 sm:mx-0">
            Discover Jamaica the way that it should be. Uninterrupted &
            Unique. All our tours are private and secluded. Transportation
            and reservations are taken care for you. Take advantage of the
            local cuisine and fruits of the island. Our tours guarantee to
            exceed all expecations of what Jamaica can offer. Our concierge
            team will plan all tours, excursions and activities so relax we
            got you!!
          </p>

          <a
          routerLink="/tours"
            class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-transparent hover:text-[#d5b634] open-sans ease transition-all"
          >
            <span class="text-sm font-medium"> View Tours </span>
          </a>
        </div>
      </div>
    </div>
  </section>

  <!-- Fleet -->

  <section class="relative my-32 py-14">
    <div class="bg-[#ECECEC] w-full h-full absolute -skew-y-6 -z-10"></div>

    <div
      class="px-4 py-16 mx-auto sm:px-6 sm:py-24 lg:mr-0 lg:pl-8 lg:pr-0"
    >
      <div
        class="grid grid-cols-1 mx-auto gap-y-8 lg:grid-cols-5 lg:items-center lg:gap-x-8 max-w-screen-2xl md:mr-auto"
      >
        <div class="col-span-2 mt-10 text-center lg:text-left lg:mx-0">
          <h2
            class="text-3xl font-bold tracking-tight sm:text-4xl text-[#d5b634] lora"
          >
            Our Luxury Fleet
          </h2>

          <p class="mt-4 text-[#171717] nunito-sans">
            We know your luxury transportation needs are important and we
            that seriously. Our team ensures that your curated
            transportation experience will be carried out as agreed. You or
            your guests will get an exclusive attention with our Curated VIP
            Transportation Services.
            <br />
            Our certified, professional drivers will make sure that you will
            have a pleasant experience on your way to your destination.
          </p>
          <br />
          <a
          routerLink="/fleet"
            class="text-[#171717] hover:underline underline-offset-4"
            >View All</a
          >

          <div class="hidden lg:mt-8 lg:flex lg:gap-4">
            <button
              class="prev-button rounded-full ease transition-all border border-[#171717] p-3 text-[#171717] hover:bg-[#171717] hover:text-white"
            >
              <span class="sr-only">Previous Slide</span>
              <svg
                class="w-5 h-5 transform -rotate-180"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 5l7 7-7 7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </button>

            <button
              class="next-button rounded-full ease transition-all border border-[#171717] p-3 text-[#171717] hover:bg-[#171717] hover:text-white hover:border-[#171717]"
            >
              <span class="sr-only">Next Slide</span>
              <svg
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 5l7 7-7 7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </button>
          </div>
        </div>

        <div class="lg:col-span-3">
          <div class="swiper-container !overflow-hidden">
            <div class="py-10 swiper-wrapper">
              <!-- escalade -->
              <div class="swiper-slide">
                <a
                  routerLink="/fleet"
                  class="md:w-96 relative block border-t-8 p-10 hover:bg-[#171717] border-[#D5B634] text-center group shadow-md mx-auto hover:text-white ease transition-all"
                >
                  <div class="h-72">
                    <img
                      src="/assets/images/sprinter-002.png"
                      alt=""
                      class="object-contain w-full h-full"
                    />
                  </div>
                  <h3 class="text-3xl font-bold text-[#D5B634]">
                    EXECUTIVE CLASS
                  </h3>
                  <span
                    class="text-[#171717] uppercase group-hover:text-white"
                    >Mercedes Sprinter</span
                  >
                  <p
                    class="mt-4 font-medium text-[#171717] group-hover:text-white nunito-sans"
                  >
                  Luxury doesn't cut corners and it shouldn't when it comes to transporting a bigger group.
                  Take advantage of our 13 passenger Mercedes Benz Sprinter,
                  fully equipped so you can relax in style, comfort, and luxury as you unwind on the road
                  while in Jamaica.
                  </p>
                </a>
              </div>
              <div class="swiper-slide">
                <a
                  routerLink="/fleet"
                  class="md:w-96 relative block border-t-8 p-10 hover:bg-[#171717] border-[#D5B634] text-center group shadow-md mx-auto hover:text-white ease transition-all"
                >
                  <div class="h-72">
                    <img
                      src="/assets/images/escalade-001.jpg"
                      alt=""
                      class="object-contain w-full h-full"
                    />
                  </div>
                  <h3 class="text-3xl font-bold text-[#D5B634]">
                    EXECUTIVE CLASS
                  </h3>
                  <span
                    class="text-[#171717] uppercase group-hover:text-white"
                    >Cadillac Escalade</span
                  >
                  <p
                    class="mt-4 font-medium text-[#171717] group-hover:text-white nunito-sans"
                  >
                    A pristine black Cadillac Escalade waiting with its
                    elegantly dressed chauffeur. Everyone is now comfortably
                    seated; your luggage is packed away nicely in the trunk
                    and the engine purrs like a panther, ready to give a
                    luxury transfer experience you will remember for days to
                    come.
                  </p>
                </a>
              </div>
              <div class="swiper-slide">
                <a
                routerLink="/fleet"
                  class="md:w-96 relative block border-t-8 p-10 hover:bg-[#171717] border-[#D5B634] text-center group shadow-md mx-auto group-hover:text-white ease transition-all"
                >
                  <div class="h-72">
                    <img
                      src="/assets/images/bmw_740i.png"
                      alt=""
                      class="object-contain w-full h-full"
                    />
                  </div>
                  <h3 class="text-3xl font-bold text-[#D5B634]">
                    EXECUTIVE CLASS
                  </h3>
                  <span
                    class="text-[#171717] uppercase group-hover:text-white"
                    >Bmw 740i</span
                  >
                  <p
                    class="mt-4 font-medium text-[#171717] group-hover:text-white nunito-sans"
                  >
                    For those that desire a large Luxury Sedan we have a BMW
                    740i for those clients that still want luxury but don't
                    need the extra room as the SUV's. The 740i sits two
                    passengers comfortably as you sit back and relax while
                    being chauffeured to your destination.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center gap-4 mt-8 lg:hidden">
        <button
          aria-label="Previous slide"
          class="prev-button rounded-full border border-[#171717] p-4 text-[#171717] hover:bg-[#D5B634] hover:text-white hover:border-[#D5B634] ease transition-all"
        >
          <svg
            class="w-5 h-5 transform -rotate-180"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 5l7 7-7 7"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </button>

        <button
          aria-label="Next slide"
          class="next-button rounded-full border border-[#171717] p-4 text-[#171717] hover:bg-[#D5B634] hover:text-white hover:border-[#D5B634] ease transition-all"
        >
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 5l7 7-7 7"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </button>
      </div>
    </div>
  </section>

  <!-- CTA -->

  <section>
    <div class="px-4 py-16 mx-auto sm:px-6 sm:py-24 lg:px-8">
      <div
        class="grid grid-cols-1 gap-8 mt-8 lg:grid-rows-2 lg:grid-cols-2"
      >
        <!-- Testimonials -->

        <section>
          <div
            class="relative mx-auto max-w-[1340px] px-4 sm:px-6 lg:mr-0 lg:px-8 lg:mt-44"
          >
            <div
              class="absolute z-10 w-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
            >
              <div class="flex justify-between mt-8 lg:mt-0">
                <button
                  class="p-3 text-4xl text-white transition-colors prev-btn focus:text-white focus:outline-none"
                >
                  <span class="sr-only">Previous Slide</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-10 h-10 transform -rotate-180"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>

                <button
                  class="p-3 ml-10 next-btn focus:text-white focus:outline-none"
                >
                  <span class="sr-only">Next Slide</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-10 h-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="lg:col-span-2 lg:mx-0">
              <h2 class="text-3xl font-medium text-center md:text-4xl lora">
                Testimonials
              </h2>
              <div
                class="testimonial-container !overflow-hidden nunito-sans"
              >
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="container p-10 mx-auto lg:mt-30">
                      <div class="w-full mx-auto text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="mr-auto w-8 h-8 text-[#d5b634] mb-8"
                          viewBox="0 0 975.036 975.036"
                        >
                          <path
                            d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"
                          ></path>
                        </svg>
                        <p class="text-lg leading-relaxed">
                          This is the way to be driven around on the island!
                          The car was immaculately clean and the driver was
                          very friendly and helpful. Was able to get to all
                          the places i wanted to visit in a timely and
                          fashionable matter. I would highly recommend!
                        </p>
                        <span
                          class="inline-block h-1 w-10 rounded bg-[#d5b634] mt-8 mb-6"
                        ></span>
                        <h2
                          class="text-sm font-medium tracking-wider uppercase title-font"
                        >
                          Annette Jones
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="container p-10 mx-auto lg:mt-30">
                      <div class="w-full mx-auto text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="mr-auto w-8 h-8 text-[#d5b634] mb-8"
                          viewBox="0 0 975.036 975.036"
                        >
                          <path
                            d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"
                          ></path>
                        </svg>
                        <p class="text-lg leading-relaxed">
                          Great Service! Was always on time, very
                          knowledgeable, and respectful. Vehicles were very
                          nice and clean. I definitely recommend Luxury Star
                          Travels 🏆
                        </p>
                        <span
                          class="inline-block h-1 w-10 rounded bg-[#d5b634] mt-8 mb-6"
                        ></span>
                        <h2
                          class="text-sm font-medium tracking-wider title-font"
                        >
                          PAUL RUDD
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Fast Track Service -->

        <div class="p-4 lg:px-16 lg:py-24">
          <div class="mx-auto text-center sm:text-left">
            <h2 class="text-2xl font-medium md:text-3xl lora">
              Fast Track Service

              <div
                class="w-40 bg-[#d5b634] h-1.5 mt-4 mx-auto sm:mx-0"
              ></div>
            </h2>
            <div class="my-6 sm:h-80">
              <img
                src="/assets/images/fast_track.jpg"
                alt=""
                class="object-cover object-center w-full h-full"
              />
            </div>

            <p class="nunito-sans">
              Every second counts, especially when arriving and departing
              from Jamaica. Waiting in long airport custom lines for hours
              takes your time away from being pool side or by the beach
              enjoying a cocktail. Why wait?
              <br />
              <br />
              Luxury Star Travels is pleased to introduce our “Fast Track
              Service” to get you off and on the plane and to your
              destination with ease and quickness. BOOK FAST TRACK SERVICE
              NOW. What is Fast Track Service & How does it work? Upon
              Arrival or Departure of Jamaica, you will be met & escorted
              bypassing long custom/security lines getting you to your
              destination fast. Avoid the wait and sign up now!!
            </p>

            <div class="mt-2 md:mt-4 open-sans">
              <a
              routerLink="/transportation"
                class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-transparent hover:text-[#d5b634] ease transition-all"
              >
                <span class="text-sm font-medium open-sans">
                  Book Fast Track
                </span>
              </a>
            </div>
          </div>
        </div>

        <!-- Concierge Service -->

        <div class="p-4 lg:px-8 lg:py-24 lg:-mt-60">
          <div class="mx-auto text-center sm:text-left">
            <h2 class="text-2xl font-medium md:text-3xl lora">
              Luxury Star Concierge Service

              <div
                class="w-60 bg-[#d5b634] h-1.5 mt-4 mx-auto sm:mx-0"
              ></div>
            </h2>
            <div class="my-6 lg:h-96">
              <img
                src="/assets/images/concierge_service.jpg"
                alt=""
                class="object-cover object-center w-full h-full"
              />
            </div>
            <p class="nunito-sans">
              Tap into our Luxury Star Concierge Service and unlock what is
              inaccessible to most. We provide knowledgeable, unique,
              personal, Luxury Concierge Services in Jamaica.
            </p>

            <div class="mt-4 md:mt-8">
              <a
              routerLink="/lifestyle-res"
                class="mt-8 inline-flex items-center border border-[#d5b634] hover:bg-[#d5b634] px-12 py-4 hover:text-white hover:bg-transparent text-[#d5b634] ease transition-all"
              >
                <span class="text-sm font-medium open-sans">
                  Learn More
                </span>
              </a>
            </div>
          </div>
        </div>

        <!-- Tours -->

        <div class="p-4 lg:py-24">
          <div class="mx-auto text-center sm:text-left">
            <h2 class="text-2xl font-medium md:text-3xl lora">
              Tours

              <div
                class="w-16 bg-[#d5b634] h-1.5 mt-4 mx-auto sm:mx-0"
              ></div>
            </h2>
            <div class="my-6 sm:h-80 lg:h-96">
              <img
                src="/assets/images/river_rafting.jpg"
                alt=""
                class="object-cover object-center w-full h-full"
              />
            </div>
            <p class="nunito-sans">
              Whether you’re traveling for business or simply for leisure,
              as we know all aspirations, ambitions and DNAs are different,
              we can customize to your taste for a unique vacation
              experience. Do you prefer immersion into.
            </p>

            <div class="mt-4 md:mt-8">
              <a
                routerLink="/tours"
                class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-transparent hover:text-[#d5b634] ease transition-all"
              >
                <span class="text-sm font-medium open-sans">
                  View Tours
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
