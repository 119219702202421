<main class="min-h-screen flex flex-col bg-[#ececec]">
  <section>
    <div class="max-w-screen-2xl px-4 mt-20 mb-14 sm:px-6 lg:px-8 mx-auto">
      <div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-x-16">
        <div class="grid grid-cols-3 gap-4 sm:grid-cols-4">
          <div
            class="col-span-3 sm:col-span-4 lg:col-span-4 max-h-[500px] overflow-hidden"
          >
            <img
              src="/assets/images/rastafari_tour.jpg"
              alt=""
              class="w-full object-cover object-center"
            />
          </div>
          <div class="hover:opacity-60 hover cursor-pointer">
            <img
              src="https://www.luxurystartravels.com/storage//luxassets/rastafari-01.jpg"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="mx-auto lg:mx-0 text-left text-[#171717]">
          <h2 class="text-4xl sm:text-6xl lora">Rastafari Tour</h2>

          <p class="mt-6 nunito-sans font-light lg:w-3/4 text-lg">
            Enjoy an authentic experience of rural Jamaica during a half-day
            ATV tour from Negril or Montego Bay. Riding on an all-terrain
            vehicle allows you to access rugged country roads that typical
            tourist vehicles cannot handle. The action-packed drive takes
            you past scenic farms, mountains, rivers, and rain forest, with
            a stop at a natural pool where you can swim. Includes lunch and
            tastings of local fruits.
          </p>
          <div
            class="grid grid-cols-2 gap-4 sm:grid-cols-3 nunito-sans mt-10 text-lg"
          >
            <div>
              <h2>Cost (per person)</h2>

              <p class="mt-1 sm:block font-light">Pricing: $150.00 pp</p>
            </div>
            <div>
              <h2>Ages</h2>

              <p class="mt-1 sm:block font-light">
                6-65, max of 24 per group
              </p>
            </div>
            <div>
              <h2>Duration</h2>

              <p class="mt-1 sm:block font-light">3 HRS</p>
            </div>
            <div>
              <h2>Start Time</h2>

              <p class="mt-1 sm:block font-light">Check Availability</p>
            </div>
            <div>
              <h2>Ticket type</h2>

              <p class="mt-1 sm:block font-light">Mobile</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div
      class="max-w-screen-2xl px-4 sm:px-6 lg:px-8 mx-auto text-[#171717]"
    >
      <div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-x-16">
        <div class="grid grid-cols-2 gap-4 ml-3.5">
          <div>
            <h2 class="text-xl mb-1 -ml-3.5">What's Included?</h2>

            <ul class="list-disc font-light text-lg">
              <li>Bottled Water</li>
              <li>Lunch</li>
              <li>Driver/guide</li>
              <li>Local guide</li>
              <li>Professional guide</li>
              <li>Hotel drop-off</li>
              <li>Hotel pick-up</li>
              <li>Use of helmet</li>
            </ul>
          </div>

          <div>
            <h2 class="text-xl mb-1">Accessibilty</h2>
            <p class="sm:block font-light text-lg">
              Not wheelchair accessible If you have questions about
              accessibility, we’d be happy to help. Just call the number
              below and reference the product code: 87804P1 +1 855 275 5071
            </p>
          </div>
        </div>

        <section class="lg:-mt-24">
          <div class="mx-auto">
            <div
              class="flex flex-col text-center w-full my-12 border border-black items-center"
            >
              <h1
                class="sm:text-3xl text-2xl title-font lora lg:text-left text-[#171717] py-5"
              >
                Book Now!
              </h1>
            </div>
            <div class="w-full pb-16">
              <form action="" class="mt-8 mb-0 space-y-4">
                <div class="flex flex-wrap gap-y-5">
                  <!-- location -->
                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      for="UserEmail"
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Pick-up Location
                    </label>

                    <select
                      name=""
                      id=""
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    >
                      <option value="" disabled selected>
                        Select a pick-up location
                      </option>
                    </select>
                  </div>
                  <!-- date -->

                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Departure Date
                    </label>

                    <input
                      type="datetime-local"
                      id="date"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>
                  <br />

                  <!-- excursion type-->

                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      Excursion Type
                    </label>

                    <select
                      name=""
                      id=""
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    >
                      <option value="" selected>One Way</option>
                    </select>
                  </div>

                  <!-- no. of persons -->

                  <div class="p-2 w-full lg:w-1/2">
                    <label
                      class="block font-medium text-[#171717] nunito-sans"
                    >
                      No. of Persons
                      <span class="text-xs lg:text-sm"
                        >(including children)</span
                      >
                    </label>

                    <input
                      type="number"
                      class="mt-1 w-full border-none shadow-sm sm:text-sm p-4 bg-[#D9D9D9] text-[#171717]"
                    />
                  </div>
                </div>

                <br />

                <button
                  type="submit"
                  class="inline-block bg-[#d5b634] py-4 font-medium text-white w-full hover:bg-[#171717] border hover:text-white transition-all ease-linear border-[#d5b634] uppercase hover:border-[#171717]"
                >
                  Get A Quote
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <section>
    <div
      class="max-w-screen-2xl px-4 sm:px-6 lg:px-8 mx-auto text-[#171717]"
    >
      <div class="lg:w-1/2 lg:-mt-44 ml-3.5">
        <h2 class="text-xl mb-1 -ml-3.5">Additional Information</h2>

        <ul class="list-disc font-light text-lg">
          <li>Confirmation will be received at time of booking</li>
          <li>Not recommended for pregnant travelers</li>
          <li>Child rate applies only when sharing with 2 paying adults</li>
          <li>Children must be accompanied by an adult</li>
          <li>
            Vegetarian option is available, please advise at time of booking
            if required
          </li>
          <li>
            The duration of transfers are approximate, the exact duration
            will depend on the time of day and traffic conditions
          </li>
          <li>Bring swimsuits and closed shoes</li>
          <li>Most travelers can participate</li>
          <li>
            This experience requires a minimum number of travelers. If it's
            canceled because the minimum isn't met, you'll be offered a
            different date/experience or a full refund
          </li>
          <li>This tour/activity will have a maximum of 24 travelers</li>
        </ul>
      </div>

      <div class="lg:w-1/2 ml-3.5 mt-10">
        <h2 class="text-xl mb-1 -ml-3.5 text-red-600">
          Cancellation Policy
        </h2>

        <ul class="list-disc font-light text-lg">
          <li>
            All sales are final and incur 100% cancellation penalties.
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section>
    <div class="mx-auto py-16 mb-20 max-w-screen-2xl">
      <div
        class="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 text-[#171717] text-center"
      >
        <div class="p-8" href="/services/digital-campaigns">
          <h2 class="text-xl font-semibold uppercase">
            Hotels in Ocho Rios
          </h2>

          <ul class="mt-1">
            <li>Transportation O/Way: Get a Quote</li>
            <li>Transportation Two Way: Get a Quote</li>
          </ul>
        </div>
        <div class="p-8" href="/services/digital-campaigns">
          <h2 class="text-xl font-semibold uppercase">
            Hotels in Kingston
          </h2>

          <ul class="mt-1">
            <li>Transportation O/Way: Get a Quote</li>
            <li>Transportation Two Way: Get a Quote</li>
          </ul>
        </div>
        <div class="p-8" href="/services/digital-campaigns">
          <h2 class="text-xl font-semibold uppercase">
            Hotels in Montego Bay
          </h2>

          <ul class="mt-1">
            <li>Transportation O/Way: Get a Quote</li>
            <li>Transportation Two Way: Get a Quote</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</main>
