import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LifestyleComponent } from './pages/lifestyle/lifestyle.component';
import { FleetComponent } from './pages/fleet/fleet.component';
import { MissionComponent } from './pages/mission/mission.component';
import { ToursComponent } from './pages/tours/tours.component';
import { TourDetailComponent } from './pages/tour-detail/tour-detail.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TransportationComponent } from './pages/transportation/transportation.component';
import { TourBookingComponent } from './pages/tour-booking/tour-booking.component';
import { FasttrackBookingComponent } from './pages/fasttrack-booking/fasttrack-booking.component';
import { LifestyleBookingComponent } from './pages/lifestyle-booking/lifestyle-booking.component';
import { GetQuoteComponent } from './pages/get-quote/get-quote.component';
import { TourCardComponent } from './layouts/tour-card/tour-card.component';
import { TranspBookingConfirmComponent } from './pages/transp-booking-confirm/transp-booking-confirm.component';
import { SharedComponentsModule } from './shared/shared-components.module';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    LifestyleComponent,
    FleetComponent,
    MissionComponent,
    ToursComponent,
    TourDetailComponent,

    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    TransportationComponent,
    TourBookingComponent,
    FasttrackBookingComponent,
    LifestyleBookingComponent,
    GetQuoteComponent,
    TourCardComponent,
    TranspBookingConfirmComponent,

    // LoadingSpinnerComponent,
    // ErrorComponent,
    // BookingsComponent,
    // LoginPage,
    // RegisterPage,
    // ResetPasswordPage,
    // CompleteSignupPage,
    // ConfirmAccountPage,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    // CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedComponentsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
