import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[appConfirmPassword]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ConfirmPasswordDirective, multi: true }]
})
export class ConfirmPasswordDirective implements Validator {

    @Input('appConfirmPassword') passwordControl?: AbstractControl;

    constructor() { }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.passwordControl?.value || !control.value) return null;

        else if (this.passwordControl!.value !== control.value) return {
            confirm: true

        };

        else return null;
    }

}
