import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UiService } from './ui.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { APIResponse } from '../models/api-response';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  onLogout$ = new BehaviorSubject<boolean>(false);

    constructor(private uiService: UiService,) { }

    handleHttpError = (err: HttpErrorResponse): Observable<APIResponse> => {
        if (!environment.production) //  console.log(err);

            // Show popup message for generic server errors
            if (err.status >= 500) {
                this.uiService.showPopup("Arggh", err.error.message, 'error');
            }

        // Show popup message for unauthorized access
        if (err.status === 401) {
            this.uiService.showPopup("Unauthorized access", err.error.message, 'error', 4000);
            this.onLogout$.next(true);
        }

        // Show request limit errors
        if (err.status === 429) {
            this.uiService.showPopup("Too many requests", err.error.message, 'error', 5000);
        }


        // Show timed out request errors
        if (err.error.message?.includes('timed out')) {
            this.uiService.showPopup("Request timed out", "That operation took too long to complete. Please try again.", 'error');
        }


        return of(APIResponse.Error(err.error.status, err.error.message));
    };

}
