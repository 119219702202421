<div class="grid h-screen place-items-center">
  <section>
      <div class="w-full max-w-lg p-12 mt-12 mb-20 text-center bg-white rounded-md shadow-md shadow-grey-400">
          <img class="w-[200px] mx-auto mb-4" src="../../../assets/logo.png">

          <h1 class="mb-8 text-3xl font-bold uppercase">Thank you for your Booking</h1>
          <p class="mb-8">
              We have sent an email to you with more information about your booking request.
              <br>
              One of our representatives will get in touch with you shortly.
              <span class="mb-8 text-lg font-bold text-red-600">
                  If you are not seeing our email in your Inbox. Check your JUNK/SPAM Folder.<br>
                  Look for an Email with the subject <br> [ LuxuryStar Booking ]
              </span>
          </p>

          <a routerLink="/" [replaceUrl]="true"
              class="mx-auto font-medium text-black shadow-md primary-button bg-tertiary-500 hover:bg-tertiary-300 shadow-grey-400">Okay</a>

      </div>
  </section>

</div>
