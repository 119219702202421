import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './components/error/error.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PopoutComponent } from './components/popout/popout.component';
import { ConfirmPasswordDirective } from './directives/confirm-password.directive';
import { FormatPhoneDirective } from './directives/format-phone.directive';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    ErrorComponent,
    LoadingSpinnerComponent,
    ConfirmPasswordDirective,
    FormatPhoneDirective,
    PaginatorComponent,
    PopoutComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  // providers:[],
  exports:[
    FormsModule,

    ErrorComponent,
    LoadingSpinnerComponent,
    PopoutComponent,
    PaginatorComponent,

    ConfirmPasswordDirective

  ]
})
export class SharedComponentsModule { }
