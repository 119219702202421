<div class="grid h-screen px-4 place-content-center relative bg-[url('/assets/images/404.jpeg')] bg-no-repeat bg-cover bg-center gap-10 lg:gap-y-20">
  <a class="cursor-pointer z-20 ">
      <img src="./assets/logo.png" alt="" class="w-3/4 md:w-5/12 mx-auto object-cover">
      </a>
  <div class="absolute inset-0 bg-black/80 z-10"> </div>
  <div class="text-center z-10 space-y-14">
    <h1 class="relative lora text-white text-9xl md:text-[300px] before:absolute before:-bottom-5 before:h-2 before:w-1/3 before:-translate-x-1/2 before:left-1/2  before:bg-[#d5b634]  ">404</h1>

    <p class="text-2xl font-bold tracking-tight text-white sm:text-4xl nunito-sans ">
      Uh-oh!
    </p>

    <p class="mt-4 text-white md:text-2xl nunito-sans">We can't find that page.</p>

    <a
      href="#"
      class="inline-block bg-[#d5b634] py-4 font-medium text-lg text-white w-full open-sans"
    >
      Go Back Home
    </a>
  </div>
</div>
