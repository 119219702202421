import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fasttrack-booking',
  templateUrl: './fasttrack-booking.component.html',
  styleUrls: ['./fasttrack-booking.component.css']
})
export class FasttrackBookingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
