import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Notify from 'simple-notify';
import Swal from 'sweetalert2';


@Injectable({
    providedIn: 'root'
})
export class UiService {

    showHeader$ = new BehaviorSubject<boolean>(true);
    showFooter$ = new BehaviorSubject<boolean>(true);

    constructor() { }


    private alertActive = false;
    showPopup(title: string, message: string, mode: 'success' | 'error' = 'success',
                            duration: number = 4000, animStyle: 'fade' | 'slide' = 'slide',
                            speed: number = 800,
                            showPos: 'x-center top' | 'x-center bottom'
                            | 'left top' | 'left bottom' | 'right top' | 'right bottom'
                            | 'center' = 'center'): void {
        if (this.alertActive) return;

        new Notify({
            status: mode,
            title: title,
            text: message,
            effect: animStyle,
            speed: speed,
            showCloseButton: false,
            autoclose: true,
            autotimeout: duration,
            gap: 16,
            distance: 24,
            type: 1,
            position: showPos
        });

        this.alertActive = true;
        setTimeout(() => this.alertActive = false, duration);
    }

    async showConfirmationDialog(title: string = 'Important Operation',
                                 text: string = "Are you sure you wish to carry out this operation?",
                                 iconType: 'success' | 'error' | 'warning' | 'info'
                                 | 'question' = 'success',
                                 confirmButtonText: string = 'Yes',
                                 confirmButtonColor: string ='#7F00BB',
                                 showCancel: boolean = true, cancelButtonColor: string = '#d33'
                                 ) {
        return await Swal.fire({
            title: title,
            text: text,
            icon: iconType,
            confirmButtonText: 'Yes',
            showCancelButton: showCancel,
            cancelButtonColor: cancelButtonColor,
            confirmButtonColor: confirmButtonColor,
            showDenyButton: true,
            denyButtonText: 'No',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
        });
    }
}
