import { Component, HostBinding, OnInit } from '@angular/core';
import { popoutAnimation as errorAnimation } from '../../animations/popout.animations';


@Component({
    selector: 'error-display',
    template: `
    <span class="text-primary-600 flex gap-2 items-center max-w-prose text-sm leading-tight">
        <svg class="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
        </svg>
        <ng-content></ng-content>
    </span>
  `,
    styles: [],
    animations: [errorAnimation]
})
export class ErrorComponent implements OnInit {

    @HostBinding('@reveal') trigger = true;

    constructor() { }

    ngOnInit(): void {
    }

}
