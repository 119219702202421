<main class="min-h-screen flex flex-col bg-[#ececec]">
  <section>
    <div class="w-full lg:h-[650px]">
      <img *ngIf="tabLuxe"
        src="/assets/hero.jpeg"
        alt="Luxury Transportation"
        class="object-cover object-center w-full h-full"/>

        <img *ngIf="tabCadillac"
        src="/assets/images/cadillac_escalade.png"
        alt="Luxury Transportation"
        class="object-cover object-center w-full h-full"/>

        <img *ngIf="tabSprinter"
        src="/assets/images/sprinter.jpg"
        alt="Luxury Transportation"
        class="object-cover object-center w-full h-full"/>

        <img *ngIf="tabBMW"
        src="/assets/images/bmw_740i.png"
        alt="BMW 740i"
        class="object-cover object-center w-full h-full"/>

        <img *ngIf="tabYukon"
        src="/assets/images/yukon-02.jpg"
        alt="GMC Yukon"
        class="object-cover object-center w-full h-full"/>

        <img *ngIf="tabConcierge"
        src="/assets/images/concierge_service.jpg"
        alt="Luxury Concierge"
        class="object-cover object-center w-full h-full"/>

        <img *ngIf="tabFastTrack"
        src="/assets/images/mbj_fast_track_01.jpg"
        alt="Fast Track Service"
        class="object-cover object-center w-full h-full"/>

    </div>
  </section>
  <div>
    <ul class="flex overflow-x-scroll text-center group">
      <!-- list item -->
      <!-- Luxe Transport -->
      <li class="flex-1 min-w-fit">
        <a *ngIf="tabLuxe; else luxeTabIA" class="block bg-[#d5b634] p-4 text-sm font-medium"
            [routerLink]="" (click)="toggleTab('LUXE')">
            Luxe Transportation
        </a>
        <ng-template #luxeTabIA>
            <a [routerLink]=""
              class="cursor-pointer relative block bg-[#171717] p-4 text-sm font-medium hover:bg-[#d5b634]"
              (click)="toggleTab('LUXE')">
              Luxe Transportation
            </a>
          </ng-template>
      </li>

      <!-- Escalade -->
      <li class="flex-1 min-w-fit">
        <a *ngIf="tabCadillac; else cadillacTabIA" class="block bg-[#d5b634] p-4 text-sm font-medium"
            [routerLink]="" (click)="toggleTab('CADILLAC')">
            Cadillac Escalade Transfers
        </a>
        <ng-template #cadillacTabIA>
            <a [routerLink]=""
              class="cursor-pointer relative block bg-[#171717] p-4 text-sm font-medium hover:bg-[#d5b634]"
              (click)="toggleTab('CADILLAC')">
              Cadillac Escalade Transfers
            </a>
          </ng-template>
      </li>
      <!-- Mercedes Sprinter -->
      <li class="flex-1 min-w-fit">
           <a *ngIf="tabSprinter; else sprinterTabIA" class="block bg-[#d5b634] p-4 text-sm font-medium"
              [routerLink]="" (click)="toggleTab('SPRINTER')">
              Mercedes Sprinter
           </a>
           <ng-template #sprinterTabIA>
              <a [routerLink]=""
                class="cursor-pointer relative block bg-[#171717] p-4 text-sm font-medium hover:bg-[#d5b634]"
                (click)="toggleTab('SPRINTER')">
                Mercedes Sprinter
              </a>
           </ng-template>
      </li>

      <!-- BMW740i -->
      <li class="flex-1 min-w-fit">
           <a *ngIf="tabBMW; else bmwTabIA" class="block bg-[#d5b634] p-4 text-sm font-medium"
              [routerLink]="" (click)="toggleTab('BMW')">
              BMW740i
           </a>
           <ng-template #bmwTabIA>
              <a [routerLink]=""
                class="cursor-pointer relative block bg-[#171717] p-4 text-sm font-medium hover:bg-[#d5b634]"
                (click)="toggleTab('BMW')">
                BMW740i
              </a>
           </ng-template>
      </li>
      <!-- GMC YUKON -->
      <li class="flex-1 min-w-fit">
           <a *ngIf="tabYukon; else yukonTabIA" class="block bg-[#d5b634] p-4 text-sm font-medium"
              [routerLink]="" (click)="toggleTab('YUKON')">
              GMC YUKON
           </a>
           <ng-template #yukonTabIA>
              <a [routerLink]=""
                class="cursor-pointer relative block bg-[#171717] p-4 text-sm font-medium hover:bg-[#d5b634]"
                (click)="toggleTab('YUKON')">
                GMC YUKON
              </a>
           </ng-template>
      </li>
      <!-- Luxury Concierge -->
      <li class="flex-1 min-w-fit">
           <a *ngIf="tabConcierge; else conciergeTabIA" class="block bg-[#d5b634] p-4 text-sm font-medium"
              [routerLink]="" (click)="toggleTab('CONCIERGE')">
              Luxury Concierge
           </a>
           <ng-template #conciergeTabIA>
              <a [routerLink]=""
                class="cursor-pointer relative block bg-[#171717] p-4 text-sm font-medium hover:bg-[#d5b634]"
                (click)="toggleTab('CONCIERGE')">
                Luxury Concierge
              </a>
           </ng-template>
      </li>
      <!-- Fast Track -->
      <li class="flex-1 min-w-fit">
           <a *ngIf="tabFastTrack; else fastTrackTabIA" class="block bg-[#d5b634] p-4 text-sm font-medium"
              [routerLink]="" (click)="toggleTab('FASTTRACK')">
              Fast Track Service
           </a>
           <ng-template #fastTrackTabIA>
              <a [routerLink]=""
                class="cursor-pointer relative block bg-[#171717] p-4 text-sm font-medium hover:bg-[#d5b634]"
                (click)="toggleTab('FASTTRACK')">
                Fast Track Service
              </a>
           </ng-template>
      </li>
    </ul>
  </div>

  <div  *ngIf="tabLuxe">
    <div class="p-8 md:p-12 lg:p-24">
      <div class="max-w-6xl text-center sm:text-left">
        <h2 class="text-2xl text-[#171717] md:text-3xl lora">
          Luxe Transportation
        </h2>
        <br />
        <p class="text-[#171717] md:mt-10 md:block nunito-sans lg:text-lg">
          We know your luxury transportation needs are important and we that seriously.
          Our team ensures that your curated transportation experience will be carried
          out as agreed. You or your guests will get an exclusive attention with our Curated
          VIP Transportation Services.

          Our certified, professional drivers will make sure that you will have a
          pleasant experience on your way to your destination.
        </p>
        <p class="text-[#171717] md:mt-10 md:block nunito-sans lg:text-lg">
            <span class="text-2xl text-[#171717] md:text-3xl lora">
                Executive SUVs <br/>
            </span>

          Luxury Star Travels have several vehicles in it’s fleet. We do only operate with
          executive vehicles like Cadillac, Lincoln and other high end luxury vehicles.
        </p>
        <p class="text-[#171717] md:mt-10 md:block nunito-sans lg:text-lg">
          <span class="text-2xl text-[#171717] md:text-3xl lora">
              Travel in Style <br/>
           </span>
          Our Curated VIP Transportation Service is available to every who needs it –
          from wedding groups and families to business executives and celebrities or
          politicians. Do you need to visit several places in while on-island in luxury style?
           We offer excellent transfer services with our luxury high class vehicles and
           immaculately dressed drivers.
          <br/>
          Please contact us for more information and the possibilities.
          Email us at sales@luxurystartravels.com or call +1 (876) 795.-7467 via telephone.
        </p>
        <!-- </div> -->
        <div class="mt-4 md:mt-8">
          <a
            routerLink="/get-quote"
            class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-[#171717] hover:text-white open-sans uppercase hover:border-[#171717] ease transition-all"
          >
            <span class="text-sm font-medium"> Get a Quote </span>
          </a>
        </div>
      </div>
    </div>
  </div>
<!-- Cadillac Escalade Content -->
  <div  *ngIf="tabCadillac">
    <div class="p-8 md:p-12 lg:p-24">
      <div class="max-w-6xl text-center sm:text-left">
        <h2 class="text-2xl text-[#171717] md:text-3xl lora">
          Cadillac Escalade Transfers
        </h2>
        <br />
        <p class="text-[#171717] md:mt-10 md:block nunito-sans lg:text-lg">
          All eyes are on you and 6 other persons in your small group, as
          you board the beautiful, pristine black 7-Seater Cadillac Escalade
          waiting with its elegantly dressed chauffeur. Everyone is now
          comfortably seated; your luggage is packed away nicely in the
          trunk and the engine purrs like a panther, ready to give a luxury
          transfer experience you will remember for days to come.
        </p>
        <p class="text-[#171717] md:mt-4 md:block nunito-sans lg:text-lg">
          Equipped with a powerful engine and advanced safety features, our
          7-seater Cadillac Escalade give you an extremely comfortable and
          spacious ride so you can relax in leather seats and stretch your
          legs after your flight. So, the next time you’re traveling with a
          group of up to 6 people, your best bet is to go with our full-size
          7-seater Escalade. You can grab your suitcases from the luggage
          carousel and meet your chauffeur in confidence that a comfortable
          driving experience awaits.
        </p>

        <div class="mt-4 md:mt-8">
          <a
            href="#"
            class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-[#171717] hover:text-white open-sans uppercase hover:border-[#171717] ease transition-all"
          >
            <span class="text-sm font-medium"> Get a Quote </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Mercedes Sprinter Content -->
  <div  *ngIf="tabSprinter">
    <div class="p-8 md:p-12 lg:p-24">
      <div class="max-w-6xl text-center sm:text-left">
        <h2 class="text-2xl text-[#171717] md:text-3xl lora">
          Mercedes Sprinter
        </h2>
        <br />
        <p class="text-[#171717] md:mt-10 md:block nunito-sans lg:text-lg">
          For those travelers with large groups that still want to travel in
          Luxury we offer our Mercedes Benz Sprinter. The Mercedes Benz Sprinter
          sits up to 14 guests comfortably while having plenty of room for luggage.
        </p>
        <div class="mt-4 md:mt-8">
          <a
            routerLink="/get-quote"
            class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-[#171717] hover:text-white open-sans uppercase hover:border-[#171717] ease transition-all"
          >
            <span class="text-sm font-medium"> Get a Quote </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- BMW 740i Content -->
  <div  *ngIf="tabBMW">
    <div class="p-8 md:p-12 lg:p-24">
      <div class="max-w-6xl text-center sm:text-left">
        <h2 class="text-2xl text-[#171717] md:text-3xl lora">
          BMW 740i
        </h2>
        <br />
        <p class="text-[#171717] md:mt-10 md:block nunito-sans lg:text-lg">
          For those that desire a large Luxury Sedan we have a BMW 740i for those
          clients that still want luxury but don't need the extra room as the SUV's.
          The 740i sits two passengers comfortably as you sit back and relax while being
          chauffeured to your destination.
        </p>

        <div class="mt-4 md:mt-8">
          <a
            routerLink="/get-quote"
            class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-[#171717] hover:text-white open-sans uppercase hover:border-[#171717] ease transition-all"
          >
            <span class="text-sm font-medium"> Get a Quote </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- YUKON Content -->
  <div  *ngIf="tabYukon">
    <div class="p-8 md:p-12 lg:p-24">
      <div class="max-w-6xl text-center sm:text-left">
        <h2 class="text-2xl text-[#171717] md:text-3xl lora">
          GMC YUKON
        </h2>
        <br />
        <p class="text-[#171717] md:mt-10 md:block nunito-sans lg:text-lg">
          Jamaica's beautiful terrain at times can be a little bumpy, not to worry. Our GMC Denali can handle all
          the roads have to offer as you and your guests sit comfortably in our 6 Passenger cabin fit for royalty.
          Our Denali exemplifies a presidential feeling that all our clients should experience. Luxury,
          dependability and comfortability is what is expected and is what is given when riding with Luxury Star
          Travels. Sit back and relax, we got you.
        </p>

        <div class="mt-4 md:mt-8">
          <a
            routerLink="/get-quote"
            class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-[#171717] hover:text-white open-sans uppercase hover:border-[#171717] ease transition-all"
          >
            <span class="text-sm font-medium"> Get a Quote </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Luxury Concierge Content -->
  <div  *ngIf="tabConcierge">
    <div class="p-8 md:p-12 lg:p-24">
      <div class="max-w-6xl text-center sm:text-left">
        <h2 class="text-2xl text-[#171717] md:text-3xl lora">
          Luxury Concierge
        </h2>
        <br />
        <p class="text-[#171717] md:mt-10 md:block nunito-sans lg:text-lg">
          It can be nerve wrecking going to another country not knowing the lay
          of the land. This is where our concierge service comes into play.
          Whether you’re traveling for play or business our concierge team takes
          care of all the details. From booking Fast Track Services, tours/excursions
          or the best parties/dinners on the island. We take care of all of that.
          While here, you will have access to our concierge team that will treat you
          like their favorite cousin visiting. So don’t worry we got you covered.
        </p>

        <div class="mt-4 md:mt-8">
          <a
            href="#"
            class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-[#171717] hover:text-white open-sans uppercase hover:border-[#171717] ease transition-all"
          >
            <span class="text-sm font-medium"> Get a Quote </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Fast Track Content -->
  <div  *ngIf="tabFastTrack">
    <div class="p-8 md:p-12 lg:p-24">
      <div class="max-w-6xl text-center sm:text-left">
        <h2 class="text-2xl text-[#171717] md:text-3xl lora">
          Fast Track Service
        </h2>
        <br />
        <p class="text-[#171717] md:mt-10 md:block nunito-sans lg:text-lg">
          Every second counts, especially when arriving and departing from Jamaica.
          Waiting in long airport custom lines for hours takes your time away from
          being pool side or by the beach enjoying a cocktail. Why wait? <br/><br/>

          Luxury Star Travels is pleased to introduce our “Fast Track Service” to
          get you off and on the plane and to your destination with ease and quickness.
          <a    class="text-[#d5b634] text-bold"
                href="https://luxurystartravels.com/services" target="_blank"
                >
                BOOK FAST TRACK SERVICE NOW
          </a>. What is Fast Track Service & How does it work?
          Upon Arrival or Departure of Jamaica, you will be met & escorted bypassing
          long custom/security lines getting you to your destination fast. Avoid the wait
          and sign up now!! <br/><br/>

          Enjoy the warmth of Jamaican hospitality and luxury with the fast track
          and personalized meet and greet services and VIP Lounges.
          Stay Connected on your trip through our phone rentals and other gadgets.
          No matter your nature of travel we cater to everyone whether you are here
          on business, getting married, travelling with family, a corporate group,
          an unaccompanied minor or senior citizens, we have a personalized service for you.
        </p>

        <div class="mt-4 md:mt-8">
          <a
            href="#"
            class="mt-8 inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-12 py-4 text-white hover:bg-[#171717] hover:text-white open-sans uppercase hover:border-[#171717] ease transition-all"
          >
            <span class="text-sm font-medium"> Get a Quote </span>
          </a>
        </div>
      </div>
    </div>
  </div>


</main>
