import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ContactComponent as ContactPage } from './pages/contact/contact.component';
import { FleetComponent as FleetPage} from './pages/fleet/fleet.component';
import { LifestyleComponent as LifestylePage} from './pages/lifestyle/lifestyle.component';
import { MissionComponent as MissionPage} from './pages/mission/mission.component';
import { ToursComponent as ToursPage} from './pages/tours/tours.component';
import { TourDetailComponent as TourDetailPage} from './pages/tour-detail/tour-detail.component';
import { NotFoundComponent as NotFoundPage} from './pages/not-found/not-found.component';

import { TransportationComponent as TransportationPage} from './pages/transportation/transportation.component';
import { FasttrackBookingComponent as FastTrackResPage} from './pages/fasttrack-booking/fasttrack-booking.component';
import { TourBookingComponent as TourResPage} from './pages/tour-booking/tour-booking.component';
import { LifestyleBookingComponent as LifestyleResPage} from './pages/lifestyle-booking/lifestyle-booking.component';
import { GetQuoteComponent as GetQuotePage} from './pages/get-quote/get-quote.component';
import { TranspBookingConfirmComponent } from './pages/transp-booking-confirm/transp-booking-confirm.component';
import { AuthModule } from './auth/auth.module';


const routes: Routes = [

  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'contact', component: ContactPage},
  {path: 'fleet', component: FleetPage},
  {path: 'lifestyle', component: LifestylePage},
  {path: 'mission', component: MissionPage},
  {path: 'tours', component: ToursPage},
  {path: 'tour-detail', component: TourDetailPage},

  {path: 'transportation', component: TransportationPage},
  {path: 'transport-booking-confirm', component: TranspBookingConfirmComponent},

  {path: 'fasttrack-res', component: FastTrackResPage},
  {path: 'tour-res', component: TourResPage},
  {path: 'lifestyle-res', component: LifestyleResPage},
  {path: 'get-quote', component: GetQuotePage},

  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
},


{ path: '', loadChildren: () => AuthModule },

  { path: '**', component: NotFoundPage },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
