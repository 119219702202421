import { Output, Input, Component, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, NgForm, NgModel, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BookingService } from 'src/app/shared/services/booking.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-transportation',
  templateUrl: './transportation.component.html',
  styleUrls: ['./transportation.component.css']
})
export class TransportationComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,
              private uiService: UiService,
              private bookingService: BookingService) { }

              defReturn: string ='na';

  retStatus : string = "no";
  retStatus_values: any = ["Yes", "No"];
  bookingFreq: string = "ONE-WAY";
  //Form Code
  //==========================================================
  @ViewChild('transportResForm') transportResForm?: NgForm;
  data = {
    guest_name: null,
    phone: null,
    email: null,
    pax: null,
    pickup_loc: null,
    pickup_loc_det: null,
    travel_dt: null,
    destination_loc_det: null,
    vehicle_type: null,
    booking_freq: null,
    ret_pickup_loc_det: null,
    ret_travel_dt: null,
    ret_destination_loc_det: null,
    other_service: null,
  }
  //Guest Name
  get guest_name() {
    return this.transportResForm?.controls['guest_name'];
  }
  get phone() {
    return this.transportResForm?.controls['phone'];
  }
  get email() {
    return this.transportResForm?.controls['email'];
  }
  get pax() {
    return this.transportResForm?.controls['pax'];
  }
  get booking_freq() {
    //this.onSetBookingFreq()
    return this.transportResForm?.controls['booking_freq'];
    //return this.retStatus == "Yes" ? "ROUNDTRIP" : "ONE-WAY";
  }

  //set booking_freq(value: any){
  //  this.booking_freq = value;
  //}

  get pickup_loc() {
    return this.transportResForm?.controls['pickup_loc'];
  }
  get pickup_loc_det() {
    return this.transportResForm?.controls['pickup_loc_det'];
  }
  get travel_dt() {
    return this.transportResForm?.controls['travel_dt'];
  }
  get destination_loc_det() {
    return this.transportResForm?.controls['destination_loc_det'];
  }
  get vehicle_type() {
    return this.transportResForm?.controls['vehicle_type'];
  }
  get ret_pickup_loc_det() {
    return this.transportResForm?.controls['ret_pickup_loc_det'];
  }
  get ret_travel_dt() {
    return this.transportResForm?.controls['ret_travel_dt'];
  }
  get ret_destination_loc_det() {
    return this.transportResForm?.controls['ret_destination_loc_det'];
  }
  get other_service() {
    return this.transportResForm?.controls['other_service'];
  }

  //==========================================================
  //End Form Code
  vehicles = [

    {name: "Cadillac Escalade",  image: "/assets/images/cadillac_escalade.png"},
    {name: "Mercedes Sprinter",  image: "/assets/images/sprinter.jpg"},
    {name: "BMW740i",            image: "/assets/images/bmw_740i.png"},
    {name: "GMC Yukon",          image: "/assets/images/yukon-01.jpg"}
  ];
  selectedImage: string ="";

  private _subscriptions = new Subscription();
  loading: boolean = false;

  ngOnInit(): void {
    //
    // this.defReturn = 'na';
    console.log(this.retStatus_values);
  }
  ngOnDestroy() : void{
    console.log(this.retStatus_values);
  }
  //------------------
  //Used to change the Booking Frequency
  onSetBookingFreq(){
    if(this.retStatus == 'Yes'){
      this.bookingFreq = "ROUNDTRIP";
      //this.booking_freq = true;
    }else{
      this.bookingFreq = "ONE-WAY";
    }
    //console.log(`Booking FREQ: ${this.bookingFreq }`);
  }
  //Used to display an image based on the selected option
  onSelect(val: any) {
    //console.log(val);
    if (val) {
      this.selectedImage = "";
      this.vehicles.map(res => {
        if(res.name == val){
          this.selectedImage = res.image;
          //console.log(`Image SRC : ${this.selectedImage}`);
        }
      });
    }
  }

  addBooking(vForm: NgForm): void {
    //this.booking_freq? == 'Yes' ? 'ROUNDTRIP' : 'ONE-WAY';
    const addSub = this.bookingService.createBooking(vForm.value).subscribe((res) => {
      if (res.status === 'success') {
          this.uiService.showPopup('Booking Created', 'Your Booking has been added successfully', 'success');
          this.router.navigateByUrl('/');
      }
      this.loading = false;
  });
  this._subscriptions.add(addSub);
    console.log(vForm.value)
  }

}
