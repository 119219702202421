import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { AwaitingConfirmationPage } from './awaiting-confirmation/awaiting-confirmation.page';
import { HttpClientModule } from '@angular/common/http';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { LoginPage } from './login/login.page';
import { RegisterPage } from './register/register.page';
import { ConfirmAccountPage } from './confirm-account/confirm-account.page';
import { CompleteSignupPage } from './complete-signup/complete-signup.page';
import { ResetPasswordPage } from './reset-password/reset-password.page';


@NgModule({
  declarations: [
    LoginPage,
    RegisterPage,

    ConfirmAccountPage,
    AwaitingConfirmationPage,
    CompleteSignupPage,
    ResetPasswordPage,

  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedComponentsModule
  ]
})
export class AuthModule { }
