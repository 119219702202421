<header aria-label="Site Header"  #header *ngIf="showHeader">
  <div class="px-4 mx-auto sm:px-6 md:pl-4 md:pr-0 lg:pl-8">
    <div class="flex items-center justify-between h-32">
      <div class="md:flex md:items-center md:gap-12">
        <a class="block text-teal-600" routerLink="/">
          <span class="sr-only">Home</span>
          <img src="/assets/logo.png" alt="" class="h-16 lg:h-20" />
        </a>
      </div>

      <div class="sm:hidden md:block">
        <nav aria-label="Site Nav"
        class="max-lg:absolute top-full left-0 w-full transition-[clip-path] duration-300 lg:![clip-path:polygon(0_0,_100%_0,_100%_100%,_0%_100%)]"
        [ngClass]="showMenu ? '[clip-path:polygon(0_0,_100%_0,_100%_100%,_0%_100%)]' : '[clip-path:polygon(0_0,_100%_0,_100%_0,_0_0)]' "
        >
          <ul class="flex items-center max-lg:text-center max-lg:flex-col lg:gap-6">
            <li>
              <a
                class="block py-2 pr-4 pl-3 hover:bg-gray-100 md:hover:bg-transparent md:p-0 relative before:absolute before:-bottom-1 before:h-1 before:w-3/5 before:origin-left before:scale-x-0 before:bg-[#D5B634] before:transition hover:before:scale-100"
                routerLink="/">
                Home

              </a>
            </li>

            <li>
              <a
                class="block py-2 pr-4 pl-3 hover:bg-gray-100 md:hover:bg-transparent md:p-0 relative before:absolute before:-bottom-1 before:h-1 before:w-3/5 before:origin-left before:scale-x-0 before:bg-[#D5B634] before:transition hover:before:scale-100"
                routerLink="/transportation"
              >
                Transportation
              </a>
            </li>
            <li>
              <a
                class="block py-2 pr-4 pl-3 hover:bg-gray-100 md:hover:bg-transparent md:p-0 relative before:absolute before:-bottom-1 before:h-1 before:w-3/5 before:origin-left before:scale-x-0 before:bg-[#D5B634] before:transition hover:before:scale-100"
                routerLink="/tours"
              >
                Tours
              </a>
            </li>

            <li>
              <a
                class="block py-2 pr-4 pl-3 hover:bg-gray-100 md:hover:bg-transparent md:p-0 relative before:absolute before:-bottom-1 before:h-1 before:w-3/5 before:origin-left before:scale-x-0 before:bg-[#D5B634] before:transition hover:before:scale-100"
                routerLink="/fleet"
              >
                Fleet
              </a>
            </li>

            <li>
              <a
                class="block py-2 pr-4 pl-3 hover:bg-gray-100 md:hover:bg-transparent md:p-0 relative before:absolute before:-bottom-1 before:h-1 before:w-3/5 before:origin-left before:scale-x-0 before:bg-[#D5B634] before:transition hover:before:scale-100"
                routerLink="/lifestyle"
              >
                Lifestyle
              </a>
            </li>

            <li>
              <a
                class="block py-2 pr-4 pl-3 hover:bg-gray-100 md:hover:bg-transparent md:p-0 relative before:absolute before:-bottom-1 before:h-1 before:w-3/5 before:origin-left before:scale-x-0 before:bg-[#D5B634] before:transition hover:before:scale-100"
                routerLink="/mission"
              >
                Mission
              </a>
            </li>
            <li>
              <a
                class="block py-2 pr-4 pl-3 hover:bg-gray-100 md:hover:bg-transparent md:p-0 relative before:absolute before:-bottom-1 before:h-1 before:w-3/5 before:origin-left before:scale-x-0 before:bg-[#D5B634] before:transition hover:before:scale-100"
                routerLink="/contact"
              >
                Contact Us</a
              >
            </li>
          </ul>
        </nav>
      </div>

      <div class="flex items-center gap-4">
            <div class="block ">
              <a
                class="bg-[#D5B634] px-2 py-3 font-medium text-white shadow lg:px-14 lg:py-4 text-sm lg:text-base"
                routerLink="/transportation"
              >
                Request a Quote
              </a>
            </div>
            <div class="flex gap-4" *ngIf="!isLoggedIn">
              <a  class="bg-[#D5B634] px-2 py-3 font-medium text-white shadow lg:px-14 lg:py-4 text-sm lg:text-base"
                routerLink="/login">
                Login
              </a>
            </div>
            <div class="flex gap-4" *ngIf="isLoggedIn">
              <a  class="bg-[#D5B634] px-2 py-3 font-medium text-white shadow lg:px-14 lg:py-4 text-sm lg:text-base"
                routerLink="/dashboard">
                Dashboard
              </a>
            </div>

        <div class="block lg:hidden">
          <button class="p-2 transition hover:text-[#171717] hover:bg-white"
            (click)="toggleMenu()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-H4282BQE69"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-H4282BQE69');
</script>
</header>

