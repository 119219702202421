<main class="min-h-screen flex flex-col bg-[#ececec]">
  <section>
    <div class="relative overflow-hidden lg:h-[650px]">
      <video class="object-cover w-full h-full" controls muted poster="/assets/videos/tours-thumbnail.png">
        <source src="/assets/videos/tours.mp4" type="video/mp4" />
      </video>
    </div>
  </section>
  <section>
    <div class="px-5 py-24 mx-auto max-w-7xl">
      <div class="mb-20 text-center">
        <h1
          class="mb-4 text-3xl font-medium text-center text-gray-900 uppercase sm:text-6xl title-font lora"
        >
          Tours
        </h1>
        <br />
        <p
          class="mt-4 sm:text-xl sm:leading-relaxed nunito-sans text-[#171717]"
        >
          Discover Jamaica the way that it should be. Uninterrupted &
          Unique. All our tours are private and secluded. Transportation and
          reservations are taken care for you. Take advantage of the local
          cuisine and fruits of the island. Our tours guarantee to exceed
          all expecations of what Jamaica can offer. Our concierge team will
          plan all tours, excursions and activities so relax we got you!!
        </p>
      </div>


      <div class="flex flex-wrap -m-4">
        <!-- THIRD ROW -->
        <!-- CARD BEGIN -->
        <div class="p-4 md:w-1/2 lg:w-1/3 group" *ngFor="let tour of tours">

          <div class="relative overflow-hidden">
            <div
              class="absolute z-10 hidden transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 group-hover:block"
            >
              <a
                href="#"
                class="inline-flex items-center border border-[#d5b634] bg-[#d5b634] px-8 py-4 text-white open-sans"
              >
                <span class="text-sm font-medium"> Learn More </span>
              </a>
            </div>
            <img
              class="object-cover object-center w-full h-96 group-hover:opacity-10"
              src="{{api_url}}{{tour['tour_image']}}"
              alt="blog"
            />
            <div class="py-3 bg-[#171717] text-center">
              <h1 class="mb-3 text-lg font-medium title-font">
                {{tour.event_nm | titlecase}}
              </h1>

              <p class="inline-flex items-center md:mb-2 lg:mb-0">
                <!-- icon -->
                <svg
                  class="w-4 h-4 mr-2"
                  fill="#171717"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="256px"
                  height="256px"
                  viewBox="-19.79 -19.79 435.29 435.29"
                  xml:space="preserve"
                  stroke="#171717"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke="#ffffff"
                    stroke-width="39.571"
                  >
                    <g>
                      <path
                        d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738 c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388 C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191 c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                      ></path>
                    </g>
                  </g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <path
                        d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738 c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388 C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191 c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                      ></path>
                    </g>
                  </g>
                </svg>
                {{tour.area_loc}}
              </p>
            </div>
          </div>

        </div>
        <!-- CARD END -->

      </div>div>
    </div>
  </section>

    <!-- Loading Spinner -->
    <ng-template #spinner>
      <div class="grid place-items-center max-h-[25rem] h-screen">
          <base-loading-spinner class="text-6xl"></base-loading-spinner>
      </div>
  </ng-template>


  <!-- Details Popover -->
  <!-- <div [@routeAnimations]="outlet.isActivated ? 'show': 'hide'">
      <router-outlet #outlet="outlet"></router-outlet>
  </div> -->
</main>
