<section
    class="min-h-screen py-24 grid place-items-center bg-no-repeat bg-cover relative">

    <!-- circles -->
    <!-- <div class="bg-[#1427A0] w-[120px] h-[120px] rounded-full shadow-md absolute bottom-40 -right-8 z-10"></div>
    <div class="bg-[#FFD200] w-[200px] h-[200px] rounded-full absolute bottom-8 right-8"></div> -->


    <div class="relative z-20 gap-y-1 w-full max-w-xl bg-white border border-gray-400 rounded-2xl shadow-lg shadow-slate-400">

        <p class="mt-6 px-4 text-slate-600 mb-8 font-bold text-center">
            One of our Business Associates will be in tuch with you shortly to get your account activated
            Check your Inbox. If you are not hearing from us within 24 hours... Check your Junk/Spam folder.
        </p>

        <form class="grid gap-y-[0.15px] lg:px-2 px-2 lg:py-[0.02rem]" (ngSubmit)="(form.valid && passwordMatches) && handleSubmit()" #form="ngForm">
            <h1 class="text-4xl font-bold text-center text-gray-900 uppercase">Welcome!</h1>
            <p class="mt-4 mb-12 font-bold text-gray-900 text-center">Become a member, Register now</p>

         <div class="mb-1 md:flex md:flex-col text-gray-900 py-2">
                 <div class="text-gray-900 max-md:flex-col md:gap-4 flex py-1 px-2">
                    <div class="form-control grow mb-1">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                        <input type="text"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            name="first_name" id="first_name" [(ngModel)]="data.frst_nm"
                            pattern="[A-Za-z\-\'\s]+" required>
                    </div>
                    <div class="form-control grow mb-1">
                        <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                        <input type="text"
                             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            name="last_name" id="last_name" [(ngModel)]="data.last_nm"
                            pattern="[A-Za-z\-\'\s]+" required>
                    </div>
                  </div>
                   <div class="relative h-1">
                      <error-display class="absolute mb-0"
                          *ngIf="((form.submitted || frst_nm?.touched) && frst_nm?.errors?.['required']) || ((form.submitted || last_nm?.touched) && last_nm?.errors?.['required'])">
                          Please enter your first and last name
                      </error-display>
                      <error-display class="absolute mb-0"
                          *ngIf="((form.submitted || frst_nm?.touched) && frst_nm?.errors?.['pattern'] && !frst_nm?.errors?.['required'] && !last_nm?.errors?.['required']) || ((form.submitted || last_nm?.touched) && last_nm?.errors?.['pattern'] && !frst_nm?.errors?.['required'] && !last_nm?.errors?.['required'])">
                          Name can only contain letters, single spaces, hyphens, and apostrophises
                      </error-display>
                    </div>
             </div>

            <div class="form-control grow mb-1">
                <label for="email_address" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Address</label>
                <input type="email"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        name="email_address" id="email_address"
                        [(ngModel)]="data.email_address" required email>
                        <small
                        id="emailHelp"
                        class="w-full text-neutral-500 dark:text-neutral-200 mt-0"
                        data-te-input-helper-ref
                        >We'll never share your email with anyone else.</small>
                <div class="relative h-6">
                    <error-display class="absolute"
                        *ngIf="(form.submitted || email_control?.touched) && email_control?.errors?.['required']">
                        Please enter your email address
                    </error-display>
                    <error-display class="absolute"
                        *ngIf="(form.submitted || email_control?.touched) && email_control?.errors?.['email']">
                        Please enter a valid email address
                    </error-display>
                    <error-display class="absolute" *ngIf="email_control?.errors?.['custom']">
                        {{email_control!.errors?.['custom']}}
                    </error-display>
                </div>
            </div>


            <div class="form-control grow mb-1">
                <label for="password" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                <input type="password"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="password" id="password" [(ngModel)]="data.password" minlength="8"
                      maxlength="255" required>
                <div class="relative h-6">
                    <error-display class="absolute"
                        *ngIf="(form.submitted || password_control?.touched) && password_control?.errors?.['required']">
                        Please enter your password
                    </error-display>
                    <error-display class="absolute"
                        *ngIf="(form.submitted || password_control?.touched) && password_control?.errors?.['minlength']">
                        Password must be 8 characters or more
                    </error-display>
                    <error-display class="absolute"
                        *ngIf="(form.submitted || password_control?.touched) && password_control?.errors?.['maxlength']">
                        Password must be less than 255 characters
                    </error-display>
                </div>

            </div>

            <div class="form-control grow mb-1">
                <label for="confirm" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                <input type="password"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        name="confirm" id="confirm" [(ngModel)]="data.confirm"
                [appConfirmPassword]="password_control" required>
                <div class="relative h-6">
                    <error-display class="absolute"
                        *ngIf="(form.submitted || confirm_control?.touched) && confirm_control?.errors?.['required']">
                        Please confirm your password
                    </error-display>
                    <error-display class="absolute"
                        *ngIf="(form.submitted || confirm_control?.touched) && confirm_control?.errors?.['confirm']">
                        Passwords do not match
                    </error-display>
                  </div>
                  <p class="w-full text-neutral-500 dark:text-neutral-200 p-0 mt-1">Already have an account?
                      <a class="text-secondary-500 hover:underline" routerLink="/login">Log in</a>
                  </p>
                  <button
                      class="mt-8 mb-4 inline-flex w-full gap-3 items-center justify-center px-6 py-4 text-black font-medium bg-[#ffd700] rounded-full border-l-neutral-950"
                      type="submit" (click)="confirm_control?.updateValueAndValidity({ onlySelf: true })">
                      Register
                      <base-loading-spinner class="fill-white text-gray-600" *ngIf="loading"></base-loading-spinner>
                  </button>
                </div>



        </form>
    </div>
</section>
