import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.css']
})
export class FleetComponent implements OnInit {
  tabLuxe: boolean = true;
  tabCadillac: boolean = false;
  tabSprinter: boolean = false;
  tabBMW: boolean = false;
  tabConcierge: boolean = false;
  tabFastTrack: boolean = false;
  tabYukon: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.toggleTab('LUXE');
  }

  toggleTab(vTab: string): void{
    console.log(this.tabLuxe);
      switch(vTab){
        case 'LUXE':{
          this.tabLuxe = true;
          this.tabCadillac = false;
          this.tabSprinter = false;
          this.tabBMW = false;
          this.tabConcierge = false;
          this.tabFastTrack = false;
          this.tabYukon = false;
          break;
        }
        case 'CADILLAC':{
          this.tabLuxe = false;
          this.tabCadillac = true;
          this.tabSprinter = false;
          this.tabBMW = false;
          this.tabConcierge = false;
          this.tabFastTrack = false;
          this.tabYukon = false;
          break;
        }
        case 'SPRINTER':{
          this.tabLuxe = false;
          this.tabCadillac = false;
          this.tabSprinter = true;
          this.tabBMW = false;
          this.tabConcierge = false;
          this.tabFastTrack = false;
          this.tabYukon = false;
          break;
        }
        case 'BMW':{
          this.tabLuxe = false;
          this.tabCadillac = false;
          this.tabSprinter = false;
          this.tabBMW = true;
          this.tabConcierge = false;
          this.tabFastTrack = false;
          this.tabYukon = false;
          break;
        }
        case 'YUKON':{
          this.tabLuxe = false;
          this.tabCadillac = false;
          this.tabSprinter = false;
          this.tabBMW = false;
          this.tabConcierge = false;
          this.tabFastTrack = false;
          this.tabYukon = true;
          break;
        }
        case 'CONCIERGE':{
          this.tabLuxe = false;
          this.tabCadillac = false;
          this.tabSprinter = false;
          this.tabBMW = false;
          this.tabConcierge = true;
          this.tabFastTrack = false;
          this.tabYukon = false;
          break;
        }
        case 'FASTTRACK':{
          this.tabLuxe = false;
          this.tabCadillac = false;
          this.tabSprinter = false;
          this.tabBMW = false;
          this.tabConcierge = false;
          this.tabFastTrack = true;
          this.tabYukon = false;
          break;
        }
        default:{
          this.tabLuxe = true;
          this.tabCadillac = false;
          this.tabSprinter = false;
          this.tabBMW = false;
          this.tabConcierge = false;
          this.tabFastTrack = false;
          this.tabYukon = false;
          break;
        }

      }
  }

}
