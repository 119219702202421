<div class="place-items-center grid h-screen">

  <section>
      <div class=" mt-12 mb-20 shadow-grey-400 w-full max-w-lg p-12 text-center bg-white rounded-md shadow-md">
          <img class="w-[200px] mx-auto mb-4" src="./assets/images/confirmation-email.png">

          <h1 class="mb-8 text-3xl font-bold uppercase text-slate-950">Thank you for registering</h1>
          <p class="mb-8 text-green-950" *ngIf="!isNotYetConfirmed">
              Your request is being processed and a representative will <br>
              contact you shortly to confirm your request and to activate your account.
              <br>
              <span class="mb-8 text-lg font-bold text-yellow-600">
                  Someone will contact you within 24 hours to follow up on your request.<br>
                  Look for an Email with the subject <br> [ Confirm Your Account ]
              </span>
          </p>
          <p class="mb-8 text-green-950" *ngIf="isNotYetConfirmed">
            Your request has not yet been processed or you have not yet <br>
            confirmed your account to have it activated. Check your email queue or contact
            our Team and we will be happy to assist you.
            <br>
            <span class="mb-8 text-lg font-bold text-yellow-600">
                Look out for for an Email with the subject <br> [ Confirm Your Account ]
            </span>
        </p>
          <a routerLink="/" [replaceUrl]="true"
              class="primary-button px-6 py-4 rounded-md bg-slate-950 hover:bg-tertiary-300 shadow-grey-400 mx-auto font-medium text-yellow-500 shadow-md">Okay</a>

      </div>
  </section>

</div>

