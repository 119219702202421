import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorService } from './error.service';
import { APIResponse } from './../models/api-response';
import { Observable, catchError } from 'rxjs';
import { Tour } from '../models/tour'
import { QueryFilters } from '../models/queryfilter';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  private TOUR_URL = environment.api_url + '/api/v1/tours';

  private _createFilterQuery(filters: QueryFilters) {
    let query = { ...filters };
    Object.keys(query).forEach((key) => {
        if (!query[key]) delete query[key];
    });
    return query;
  }

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  getAllTours(filters?: QueryFilters): Observable<APIResponse<Tour[]>> {
      let params = (filters) && this._createFilterQuery(filters);
      return this.http.get<APIResponse<Tour[]>>(this.TOUR_URL, { params })
                 .pipe(catchError(this.errorService.handleHttpError));
  }



}
